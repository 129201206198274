import { PagingData } from 'src/app/core/models/api-response.model';
import { PermissionResourcesEnum } from './permissions.model';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

export interface SideBar {
    label: string;
    router: string;
    disabled: boolean;
    hide?: boolean;
}

export interface Signout {
    deviceID: string | null;
    accessToken: string | null;
    refreshToken: string | null;
}

export interface DropdownList {
    value: string | number | null;
    text: string;
    description?: string;
    hide?: boolean;
    data?: any;
    selected?: boolean
    customValueLabel?: string
    groupBy?: string
    isReadOnly?: boolean
}

export interface TcNotification {
    alertTitle: string;
    alertDescription: string;
    isRead: boolean;
    alertType: NotificationTypeEnum;
    objectType: string;
    objectId: number;
    userId: number;
    createDate: Date;
    id: number;
    data: string;
    Id: number;
    UserId: number;
    ObjectType: string;
    AlertType: string;
}

export interface NotificationCount {
    count: number;
}

export enum NotificationReadTypeEnum {
    Unread = 'Unread',
    Read = 'Read',
    All = 'All',
}

export interface RegisterNotification {
    id: number;
    notificationToken: string | null;
    notificationType: string;
    deviceId: string | null;
    deviceType: string;
}

export enum NotificationTypeEnum {
    Submitted = 'Submitted',
    UpdateInformationSubmitted = 'UpdateInformationSubmitted',
    PreviousEmployerfeedback = 'PreviousEmployerfeedback',
    MVRReportReceived = 'MVRReportReceived',
    PSPReportReceived = 'PSPReportReceived',
    DrugTestModified = 'DrugTestModified',
    NewIncident = 'NewIncident',
    ReadyIncident = 'ReadyIncident',
    RemoveIncident = 'RemoveIncident',
    RequiredDriversFulfilled = 'RequiredDriversFulfilled',
    HiringRejected = 'HiringRejected',
    ReasonableSuspicion24HoursDelation = 'ReasonableSuspicion24HoursDelation',
    PairingRequestReceived = 'PairingRequestReceived',
    AnnualReviewIsDue = "AnnualReviewIsDue",
    MaintenanceIsDue = "MaintenanceIsDue"
}

export class Completer<T> {
    public readonly promise: Promise<T>;
    private _isResolved: boolean = false;
    public get isResolved() {
        return this._isResolved;
    }

    public complete(value: PromiseLike<T> | T) {
        if (!this.isResolved) this._complete(value);
    }

    public reject(reason?: any) {
        if (!this.isResolved) this._reject(reason);
    }

    // @ts-ignore
    private _complete: (value: PromiseLike<T> | T) => void;
    // @ts-ignore
    private _reject: (reason?: any) => void;

    public constructor() {
        this.promise = new Promise<T>((resolve, reject) => {
            this._complete = resolve;
            this._reject = reject;
        });
        this.promise.finally(() => (this._isResolved = true));
    }
}

export interface SideBarEntry {
    label: string;
    router: string;
    disabled: boolean;
    hide: boolean;
}
interface MultiFields {
    key: string;
    isEmpty: boolean;
}
interface PageFields {
    fieldName: string;
    isEmpty: boolean;
    fieldKey: string | MultiFields[];
    fieldId?: string;
    disableClick?: boolean
}

export interface RequiredList {
    pageName: string;
    pageFields: PageFields[];
    pageRoute: string;
}

export interface TimeZone {
    id: string;
    displayName: string;
    standardName: string;
}

export enum ActionViewTypeEnym {
    Add = 'Add',
    Edit = 'Edit',
    View = 'View',
}

export interface Employees {
    companyUserId: number;
    id: number;
    companyUserCode: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    zipCode: string;
    title: string;
    faxNumber: string;
}

export interface ApiFilterOptions {
    isArchived?: boolean
    isActive?: boolean
}

export interface GetDataOptions {
    searchString?: string
    pageIndex: number
    pageSize?: number
    sort?: string
    searchById?: string | number | null
}

export interface ActionResponse<T> {
    success: boolean;
    data?: PagingData<T> | T[];
    errMsg?: string;
    isRefresh?: boolean
    autoCompList?: DropdownList[]
}

export interface AutocompleteDropdown<T> {
    getAllData: boolean;
    searchFromApi: boolean;
    searchUseString?: boolean;
    getData: (options: GetDataOptions) => Promise<ActionResponse<T>>;
    allowAddNew?: boolean;
    permissionResource?: PermissionResourcesEnum;
    addNewLabel?: string;
    addNewIcon?: string;
    addNewFunc?: () => Promise<ActionResponse<T>>;
    itemsToExclude?: number[];
    resetList?: boolean
    fieldConfig?: {
        value: string
        textFields: string[],
        isReadOnly?: string
    }
    // fieldConfig:
    // {
    //     value: "id",
    //     textFields: ["assetCode", "vinNumber", "plateNumber"]
    // }
}

export interface TCDropdown<T> {
    getData: (options: GetDataOptions) => Promise<ActionResponse<T>>;
    fieldConfig?: {
        value: string
        textFields: string[],
    }
}

export enum EmployeeTypeEnum {
    Driver = 'Driver',
    Office = 'Office',
    All = 'All',
}

export interface ImageViewer {
    id: number;
    url?: string;
    selected?: boolean;
    imageId: number;
    src: Observable<SafeUrl>
    type?: string
}

export interface FilesListAutocompleteConfig {
    optionsList: DropdownList[];
    allowEdit: boolean;
    required: boolean;
    strictValue: boolean;
    label: string;
    placeholder?: string;
    readonly?: boolean;
}

export interface AppFilesList {
    documentId: number | null;
    id: number;
    autocompleteValue?: string;
    textField?: string;
    localId?: number;
}

export interface DocumentsFpList {
    documentId: number;
    documentTitle: string;
    documentType: string;
    id: number;
    data?: any;
}

export enum InputUnitTypeEnum {
    gal = 'gal',
    lbs = 'lbs',
    percentage = '%',
    miles = 'miles',
    feet = 'feet',
    currency = '$',
    hp = 'hp',
    ml = 'ml',
    ft = 'ft',
    inUsd = 'in USD',
    mi = 'mi',
    days = 'days',
    num=' '
}

export class BreadcrumbsConfig {
    hasTabs: boolean = false;
    backButtonLink: string | null = null;
    mainTitle: string = "";
    mainInfo: string = "";
    editMode: boolean = false;
    inverseIndex?: number;
    status?: {
        statusClasses?: string;
        statusLabel?: string;
    };
    newObjectTitle: string = "";
    list: Breadcrumb[] = [];
    // objectToInsert?: {
    //     item: BreadcrumbsList | null;
    //     index?: number | null;
    //     isNew?: boolean;
    // } | null;
    notSetNameFromRoute?: boolean

    public constructor(init: BreadcrumbsConfig) {
        Object.assign(this, init);
    }

    public pushBreadcrumb?(newValue: Breadcrumb) {
        if (this.list.length > 0 && this.list[this.list.length - 1].id == newValue.id) {
            if (this.list[this.list.length - 1].info) {
                newValue.info = this.list[this.list.length - 1].info
            }
            Object.assign(this.list[this.list.length - 1], newValue);
        } else {
            this.list.push(newValue)
        }
    }
}

export class Breadcrumb {
    id: string = "";
    title: string = "";
    info: string | null = null;
    routerLink: string | null = null;
}

export interface AddressObject {
    address1: string,
    address2: string,
    city: string,
    state: string,
    zipCode: string,
    formattedAddress?: string
    lat: number
    lng: number
    url: string | null
}

export enum DateTypeEnum {
    date = 'date',
    time = 'time',
    dateTime = 'dateTime',
    dateOnly = 'dateOnly'
}