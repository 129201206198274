import { ContextService } from 'src/app/shared/services/context.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

enum ResetPasswordPageEnum {
    alreadyDone = "alreadyDone",
    wrongAccount = "wrongAccount",
    success = 'success',
    resetPassowrdLinkExpired = 'resetPassowrdLinkExpired',
    resetPassowrdNoLongerValid = 'resetPassowrdNoLongerValid',
    verifyEmailNoLongerValid = 'verifyEmailNoLongerValid',
    successInvitaion = 'successInvitaion',
    passwordResetRequested = 'passwordResetRequested',
    passwordChangedSuccessfully = 'passwordChangedSuccessfully',
    accountActivationLinkNoLongerValid = 'accountActivationLinkNoLongerValid',
    yourAccountHasBeenActivated = 'yourAccountHasBeenActivated',
    link_expired = 'link_expired'
}

@Component({
    selector: 'app-token-validations',
    templateUrl: './token-validations.component.html',
    styleUrls: ['./token-validations.component.scss']
})
export class TokenValidationsComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private notificationService: NotificationsService,
        public contextService: ContextService,
        private router: Router
    ) { };

    pageShow: ResetPasswordPageEnum | null = null;
    currentEmail: string | null = this.contextService.getEmail();
    newEmail: string | null = this.route.snapshot.paramMap.get('email');
    year: number = new Date().getFullYear();
    companyId: number | null = null;
    get PagesEnym() {
        return ResetPasswordPageEnum;
    }

    ngOnInit(): void {
        this.companyId = Number(this.route.snapshot.queryParams['companyId']);
        switch (this.route.snapshot.paramMap.get('type')) {
            case 'resetPasswordExpired':
                this.pageShow = ResetPasswordPageEnum.resetPassowrdLinkExpired;
                break;
            case 'invalidToken':
                this.pageShow = ResetPasswordPageEnum.resetPassowrdNoLongerValid;
                break;
            case 'success':
                this.pageShow = ResetPasswordPageEnum.success;
                break;
            case 'wrongAccount':
                this.pageShow = ResetPasswordPageEnum.wrongAccount;
                break;
            case 'emailAlreadyVerified':
                this.pageShow = ResetPasswordPageEnum.alreadyDone;
                break;
            case 'verifyEmailNoLongerValid':
                this.pageShow = ResetPasswordPageEnum.verifyEmailNoLongerValid;
                break;
            case 'successInvitaion':
                this.pageShow = ResetPasswordPageEnum.successInvitaion;
                break;
            case 'passwordResetRequested':
                this.pageShow = ResetPasswordPageEnum.passwordResetRequested;
                break;
            case 'passwordChangedSuccessfully':
                this.pageShow = ResetPasswordPageEnum.passwordChangedSuccessfully;
                break;
            case 'accountActivationLinkNoLongerValid':
                this.pageShow = ResetPasswordPageEnum.accountActivationLinkNoLongerValid;
                break;
            case 'yourAccountHasBeenActivated':
                this.pageShow = ResetPasswordPageEnum.yourAccountHasBeenActivated;
                break;
            case 'link-expired':
                this.pageShow = ResetPasswordPageEnum.link_expired;
                break;
        }

    }

    async logout() {
        await this.authService.signout(this.notificationService.getDeviceId());
        await this.authService.signOut();
    }

    goToWelcomePage() {
        let companyId = this.contextService.getCompanyId();
        this.router.navigate(['welcome-page'], { queryParams: { companyId } });
    }

    contactSupport() {
        window.open(
            'mailto:support@truckincentral.com',
            '_blank' // <- This is what makes it open in a new window.
        );
    }
}
