<!-- <div class="container">
    <div class="row w-100 main-content">
        <div class="image-section">
            <img src="../../../assets/images/safe-bro.png">
        </div>
        <div class="content">
            <h4 class="heading-4">{{"INSUFFICIENT_PRIVILEGES" |translate}}</h4>
            <p class="body-short-1 desc">
                {{"YOU_DON'T_HAVE_PERMISSIONS_TO_ACCESS_THIS_CONTENT" |translate}}
            </p>
            <button class="primary-button-large mt-4" (click)="contact()">{{"CONTACT_ADMINISTRATOR" |translate}}</button>
        </div>
        
    </div>
</div> -->

<div class="page-container">
    <div class="page-section">
        <div class="logo">
            <!-- <img src="assets/images/site-logo.svg"> -->
        </div>
        <div class="body">
            <h4 class="heading-4">{{"INSUFFICIENT_PRIVILEGES" |translate}}</h4>
            <p class="body-short-1 desc">
                {{"YOU_DON'T_HAVE_PERMISSIONS_TO_ACCESS_THIS_CONTENT" |translate}}
                @if(employeeCompanies > 1){
                {{"OR_SWITCH_TO_ANOTHER_COMPANY" |translate}}
                }
            </p>
            <div class="d-flex mt-4" style="gap: 1.5rem;">
                <button class="primary-button-large" (click)="contact()">{{"CONTACT_ADMINISTRATOR" |translate}}</button>
                <button class="secondary-button-large" *ngIf="employeeCompanies > 1"
                    (click)="selectCompanyDialog()">{{"SWITCH_COMPANY" |translate}}</button>
            </div>
        </div>
        <div class="footer">
        </div>
    </div>
    <div class="image-section">
        <img src="assets/images/password-half-image.png" style="height: 86vh;position: relative;top: -3.25rem;">
    </div>
</div>