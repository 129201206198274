import { Component, Inject, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SharedApiService } from '../../services/shared-api.service';
import { SignatureDetials } from 'src/app/core/models/shared.model';
import { FileService } from '../../services/file.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-signature-details',
    templateUrl: './signature-details.component.html',
    styleUrls: ['./signature-details.component.scss'],
})
export class SignatureDetailsComponent implements OnInit {
    signatureDetials: SignatureDetials | null = null;
    signatureImgLink: string = '';
    isBusy: boolean = false;

    form = new FormGroup({
        fullName: new FormControl(''),
        email: new FormControl(''),
        dated: new FormControl(''),
        timeStamp: new FormControl(''),
        ipAddress: new FormControl(''),
        deviceUsed: new FormControl(''),
    })

    constructor(
        private dialogRef: MatDialogRef<SignatureDetailsComponent>,
        @Inject(MAT_DIALOG_DATA) public data: { objectId: number , endorsementAgentId: number },
        private sharedAPIServie: SharedApiService,
        private fileService: FileService,
        private datePipe: DatePipe,
        private translate: TranslateService
    ) { }

    async ngOnInit(): Promise<void> {
        // this.signatureDetials = (await this.getSignatureDetials()).data;
        if(this.data.objectId) {
            this.getSignatureDetials();
        } else if(this.data.endorsementAgentId){
            this.getNewSignatureDetials();
        }
    }

    cancel() {
        this.dialogRef.close(null);
    }

    async getSignatureDetials() {
        try {
            this.isBusy = true;
            const response = await this.sharedAPIServie.getSignatureDetials(
                this.data.objectId
            );
            if (response.success && response.data) {
                const timestamp = response.data.signingTimeStamp;
                if (timestamp) {
                    const datePart = this.datePipe.transform(timestamp, 'd/MM/y');
                    const timePart = this.datePipe.transform(timestamp, 'hh:mm:ss');
                    const atTranslation = this.translate.instant('AT');
                    this.form.controls.timeStamp.setValue(`${datePart} ${atTranslation} ${timePart} (UTC)`)
                }
                this.form.patchValue({
                    fullName: response.data.signerName,
                    email: response.data.signerEmail,
                    dated: response.data.singingDate,
                    ipAddress: response.data.ipAddress,
                    deviceUsed: response.data.deviceInfo
                });
                if (response.data.signedDocumentId) {
                    const response2 = await lastValueFrom(this.fileService
                        .getFile(response.data.signedDocumentId));
                    this.signatureImgLink = URL.createObjectURL(response2);
                }
            }
        }
        catch (error: any) {

        } finally {
            this.isBusy = false;
        }
    }

    async getNewSignatureDetials(){
        try {
            let response = await this.sharedAPIServie.getSigningDetials('EndorsementAgent_EndorsementInformation_Agent',this.data.endorsementAgentId);
            if (response.success && response.data) {
                let data = response.data[0];
                const timestamp = data.timeStamp;
                if (timestamp) {
                    const datePart = this.datePipe.transform(timestamp, 'y-d-MM');
                    const timePart = this.datePipe.transform(timestamp, 'hh:mm:ss');
                    const atTranslation = this.translate.instant('AT');
                    this.form.controls.timeStamp.setValue(`${datePart} ${atTranslation} ${timePart} (UTC)`)
                }
                this.form.patchValue({
                    fullName: data.signeeName,
                    email: data.signeeEmail,
                    dated: data.signDate,
                    ipAddress: data.ipAddress,
                    deviceUsed: data.deviceInfoFromHttp
                });
                if (data.signatureImageDocumentId) {
                    const response2 = await lastValueFrom(this.fileService
                        .getFile(data.signatureImageDocumentId));
                    this.signatureImgLink = URL.createObjectURL(response2);
                }
            }
        } catch(error: any) {

        } finally {

        }
    }
}
