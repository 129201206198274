import { data } from 'jquery';
import { ThrottleCallService } from './../../services/throttle-call.service';
import {
    ControlValueAccessor,
    Validator,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    AbstractControl,
    ValidationErrors,
    UntypedFormControl,
} from '@angular/forms';
import { ActionResponse, DropdownList, GetDataOptions } from './../../model/shared.model';
import {
    Component,
    Input,
    OnInit,
    forwardRef,
    Inject,
    HostListener,
    ElementRef,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
} from '@angular/core';
import { AutocompleteDropdown } from '../../model/shared.model';
import { PermissionsService } from '../../services/permissions.service';
import { PagingData } from 'src/app/core/models/api-response.model';

@Component({
    selector: 'app-autocomplete-dropdown-multi-select',
    templateUrl: './autocomplete-dropdown-multi-select.component.html',
    styleUrls: ['./autocomplete-dropdown-multi-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AutocompleteDropdownMultiSelectComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => AutocompleteDropdownMultiSelectComponent),
        },
    ],
})
export class AutocompleteDropdownMultiSelectComponent<T>
    implements
    OnInit,
    ControlValueAccessor,
    Validator,
    AfterViewInit,
    OnChanges,
    OnDestroy {
    @HostListener('window:scroll', []) onWindowScroll() {
        this.showDropdownMenu = false;
        this.relocateList();
    }

    @HostListener('window:resize', []) onWindowResize() {
        this.showDropdownMenu = false;
        this.relocateList();
    }

    @ViewChild('dropdownContainer') dropdownContainer: ElementRef | null = null;
    @ViewChild('list') list: ElementRef | null = null;
    @ViewChild('inputSearch') inputSearch: ElementRef | null = null;
    isBusy: number = 0;
    containerTopInit: number | null = null;
    autocompleteDropdownConfig: AutocompleteDropdown<T> | null = null;
    @Input() set autoCompleteOptions(data: AutocompleteDropdown<T> | null) {
        this.autocompleteDropdownConfig = data;
        // if (this.autocompleteDropdownConfig?.getAllData)
        //     this.optionsApi.pageSize = 222222224;
        // this.getList();
    }
    @Input() strictValue: boolean = false;
    @Input() optionsList: DropdownList[] = [];
    @Input() readonly: boolean = false;
    @Input() placeholder: string = '';
    @Input() customValueLabel: any | null = null;
    @Input() tooltipTemplate: any | null = null;
    @Input() tooltipWidth: string | null = null;
    @Input() showDropdownMenu: boolean = false;
    @Input() id: string = '';
    @Input() height: string = '17rem';
    @Input() viewMode: boolean = false;
    @Input() filedError: boolean = false;
    @Input() isTableEditable: boolean = false;
    @Input() openlistInTop: boolean = false;
    @Input() maxlength: number = 50;
    @Input() removedItems: number[] = [];
    @Input() isPhoneNumber: boolean = false;
    @Output() dataObject = new EventEmitter<T>();
    @Output() blurInput = new EventEmitter<T>();
    notSelectedFromAvailable: boolean = false;
    originalValue: string = '';
    positionInterval: any;
    optionsApi: GetDataOptions = {
        sort: '',
        pageSize: 25,
        pageIndex: 0,
        searchString: '',
    };
    label: string = '';
    myControl = new UntypedFormControl('');
    listOfValues: DropdownList[] = [];
    dropdownSelectedObj: DropdownList | null = null;
    isLoading: boolean = false;
    arrowClicked: boolean = false;
    showInput: boolean = false;
    validationMessages: Set<string> = new Set();
    constructor(
        private permissionsService: PermissionsService,
    ) {
        parent;
    }

    @HostListener('document:click', ['$event']) onDocClick(event: any) {
        const outsideClick =
            typeof event.composedPath === 'function' &&
            !event
                .composedPath()
                .includes(this.dropdownContainer?.nativeElement);

        if (outsideClick) {
            this.itemIndex = 1;
            if (this.showDropdownMenu) {
                this.showInput = false;
            }
            this.showDropdownMenu = false;
            if (this.myControl.value) {
                if (this.strictValue) {
                    this.myControl.setValue("");
                    if (this.autocompleteDropdownConfig?.searchFromApi) {
                        this.optionsApi.searchString = "";
                        this.optionsList = [];
                        this.optionsApi.searchById = 0;
                        this.optionsApi.pageIndex = 0;
                        this.getList();
                    }
                } else {
                    let controlValue = this.myControl.value;
                    if (this.isPhoneNumber) {
                        if (controlValue.length == 0) {
                            return;
                        }
                        if (controlValue.length < 14) {
                            this.validationMessages.add(
                                `Please lengthen this text to 14 characters or more (you are currently using ${controlValue.length} characters).`
                            );
                            return;
                        }
                    }
                    let item = this.listOfValues.find(e => e.value == controlValue);
                    if (item == undefined) {
                        this.listOfValues.push({
                            text: controlValue,
                            value: controlValue
                        });
                        this.myControl.setValue("");
                        this.setControlValue();
                    } else {
                        this.myControl.setValue("");
                    }
                }
            }
        }
    }

    ngOnInit(): void {
        if (this.autocompleteDropdownConfig?.allowAddNew) {
            if (this.autocompleteDropdownConfig.permissionResource)
                this.autocompleteDropdownConfig.allowAddNew =
                    this.permissionsService.isAddGranted(
                        this.autocompleteDropdownConfig.permissionResource
                    );
            else this.autocompleteDropdownConfig.allowAddNew = false;
        }

        this.positionInterval = setInterval(() => {
            this.relocateList();
        }, 1);
    }

    async addItem() {
        this.showInput = true;
        setTimeout(async () => {
            let elm = document.getElementById(`autocomplete-dropdown-input-${this.id}`);
            if (elm) {
                elm.focus();
                this.showDropdownMenu = true;
                if (this.autocompleteDropdownConfig) {
                    this.optionsApi.pageIndex = 0;
                    if (this.autocompleteDropdownConfig?.getAllData) {
                        this.optionsApi.pageSize = 222222224;
                    } else {
                        this.optionsApi.pageSize = 25;
                    }
                    this.optionsList = [];
                    await this.getList();
                }
            }
        });
    }

    inputClick() {
        this.showDropdownMenu = true;
        this.relocateList();
    }

    enterClick() {
        if (this.myControl.value) {
            if (this.strictValue) {
                this.myControl.setValue("");
                if (this.autocompleteDropdownConfig?.searchFromApi) {
                    this.optionsApi.searchString = "";
                    this.optionsList = [];
                    this.optionsApi.searchById = 0;
                    this.optionsApi.pageIndex = 0;
                    this.getList();
                }
            } else {
                this.validationMessages.clear();
                let controlValue = this.myControl.value;
                if (this.isPhoneNumber) {
                    if (controlValue.length == 0) {
                        return;
                    }
                    if (controlValue.length < 14) {
                        this.validationMessages.add(
                            `Please lengthen this text to 14 characters or more (you are currently using ${controlValue.length} characters).`
                        );
                        return;
                    }
                }
                let item = this.listOfValues.find(e => e.value == controlValue);
                if (item == undefined) {
                    this.listOfValues.push({
                        text: controlValue,
                        value: controlValue
                    });
                    this.myControl.setValue("");
                    this.setControlValue();
                } else {
                    this.myControl.setValue("");
                }
            }
        }
    }

    arrowClick() {
        this.arrowClicked = true;
        this.showDropdownMenu = !this.showDropdownMenu;
        this.relocateList();
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngAfterViewInit() {
        this.relocateList();
    }

    private tc = new ThrottleCallService<ActionResponse<T>>();
    async getList(delay = 0) {
        try {
            this.isLoading = true;
            if (delay == 0) this.isBusy++;
            if (this.autocompleteDropdownConfig) {
                if (this.autocompleteDropdownConfig.resetList) {
                    this.optionsList = [];
                }
                let response = await this.tc.call(
                    () => this.autocompleteDropdownConfig!.getData(this.optionsApi),
                    delay
                );
                if (response) {
                    if (response.data && this.isPaginated(response.data)) {
                        this.hasNextPage = response.data.hasNextPage;
                    }
                    if (response.autoCompList) {
                        this.optionsList.push(...response.autoCompList);
                    }
                    this.optionsList.forEach(e => {
                        this.listOfValues.forEach(i => {
                            if (i.value == e.value) {
                                e.selected = true;
                            }
                        });
                        this.removedItems.forEach((i: number) => {
                            if (i == e.value) {
                                e.selected = true;
                            }
                        })
                    })
                }
            }
        }
        catch (error: any) {

        } finally {
            this.isLoading = false;
        }
    }

    onInputSearchChange(event: any) {
        let value = this.myControl.value;
        if (this.autocompleteDropdownConfig?.searchFromApi) {
            this.optionsApi.searchString = value;
            this.optionsList = [];
            this.optionsApi.searchById = 0;
            this.optionsApi.pageIndex = 0;
            this.getList(500);
        }
        this.relocateList();
    }

    changeValue(item: any) {
        this.listOfValues.push({
            text: item.text,
            value: item.value,
            customValueLabel: this.customValueLabel != null && item.data ? this.customValueLabel(item.data) : ""
        });
        item.selected = true;
        this.originalValue = item.text;
        if (item.data)
            this.dataObject.emit(item?.data);
        this.setControlValue();
    }

    setControlValue() {
        // this.itemIndex = 1;
        this.itemIndex++;
        this.markAsTouched();
        const values = this.listOfValues.map(e => e.value);
        this.onChange(values);
        setTimeout(() => {
            this.list?.nativeElement.focus();
            let el = document.getElementById(`menu-item-${this.itemIndex}`);
            if (el) {
                el.classList.add('item-hover');
            }
            this.scrollToElementById(`menu-item-${this.itemIndex}`);
        });

    }

    removeItem(item: DropdownList) {
        let option = this.optionsList.find(e => e.value == item.value);
        if (option) {
            option.selected = false;
        }
        item.selected = false;
        let index = this.listOfValues.findIndex(e => e.value == item.value);
        this.listOfValues.splice(index, 1);
        this.setControlValue();
    }

    async deleteSearch() {
        this.myControl.setValue(null);
        this.showDropdownMenu = true;
        let el = document.getElementById(
            `autocomplete-dropdown-input-${this.id}`
        );
        if (el) {
            el.focus();
        }
        if (this.autocompleteDropdownConfig) {
            this.optionsApi.searchString = '';
            this.optionsList = [];
            this.optionsApi.searchById = 0;
            this.optionsApi.pageIndex = 0;
            this.optionsApi.pageSize = 50;
            this.firstCall = false;
            await this.getList(500);
        }
        this.markAsTouched();
        this.onChange(null);
    }



    onChange = (value: any) => { };
    onTouched = () => { };
    touched = false;
    disabled = false;

    validate(c: AbstractControl): ValidationErrors {
        if (this.strictValue && this.myControl.value) {
            let obj = this.optionsList.find(
                (e) =>
                    e.text == this.originalValue ||
                    e.value == this.originalValue
            );
            if (obj == undefined) {
                this.notSelectedFromAvailable = true;
                return {
                    notSelectedFromAvailable: {
                        notAvailable: true,
                    },
                };
            } else {
                this.notSelectedFromAvailable = false;
                return {};
            }
        } else {
            return {};
        }
    }

    registerOnValidatorChange?(fn: () => void): void {

    }

    async writeValue(values: any[]) {
        this.listOfValues = [];
        if (values && values.length) {
            values.forEach(e => {
                if (this.autocompleteDropdownConfig && this.autocompleteDropdownConfig.fieldConfig) {
                    const textValue = this.autocompleteDropdownConfig.fieldConfig.textFields.map(index => e[index]).join(' | ');
                    this.listOfValues.push({
                        value: e[this.autocompleteDropdownConfig.fieldConfig.value],
                        text: textValue,
                        isReadOnly: this.autocompleteDropdownConfig.fieldConfig.isReadOnly && e[this.autocompleteDropdownConfig.fieldConfig.isReadOnly] ? true : false
                    })
                } else {
                    this.listOfValues.push({
                        value: e.value,
                        text: e.text
                    });
                }
            });
            setTimeout(() => {
                this.setControlValue();
            });
        }
    }

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    private relocateList() {
        let containerWidth =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().width;
        let containerLeft =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().left;

        let containerTop =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().top;

        let listWidth = this.list?.nativeElement.getBoundingClientRect().width;
        let listLeft = this.list?.nativeElement.getBoundingClientRect().left;
        let listTop = this.list?.nativeElement.getBoundingClientRect().top;
        let listHeghit = this.list?.nativeElement.getBoundingClientRect().height;
        // console.log(listHeghit)
        if (this.list) {
            this.list.nativeElement.style.width! = containerWidth + 'px';
            this.list.nativeElement.style.left! = containerLeft + 'px';
            if (this.openlistInTop) {
                this.list.nativeElement.style.top! = containerTop - listHeghit - 3 + 'px';
            } else {
                this.list.nativeElement.style.top! = containerTop + 32 + 'px';
            }
            // if (this.notSelectedFromAvailable) {
            //     this.showDropdownMenu = false;
            // } else this.showDropdownMenu = !this.showDropdownMenu ;
            if (this.containerTopInit) {
                if (
                    this.containerTopInit != containerTop &&
                    this.showDropdownMenu
                ) {
                    // this.showDropdownMenu = false;
                }
            } else {
                this.containerTopInit = containerTop;
            }
            this.containerTopInit = containerTop;
        }
        if (this.optionsList.length > 0) {
            this.isLoading = false;
        }
    }

    async addNew() {
        if (
            this.autocompleteDropdownConfig &&
            this.autocompleteDropdownConfig.addNewFunc
        )
            await this.autocompleteDropdownConfig.addNewFunc();
    }


    /////////////////////////// key events ////////////////////////////////////
    // @HostListener('document:keydown', ['$event'])
    // handleKeyboardEvent(event: KeyboardEvent) {
    //     switch (event.key) {
    //         case 'ArrowDown':
    //             this.removeActiva();
    //             this.arrowDown();
    //             break;
    //         case 'ArrowUp':
    //             this.removeActiva();
    //             this.arrowUp();
    //             break;
    //         case 'Enter':
    //             this.enter();
    //     }
    // }

    itemIndex: number = 1;

    enter() {
        let el = document.getElementById(`menu-item-${this.itemIndex}`);
        if (el) {
            this.changeValue(el.getAttribute('value'));
            setTimeout(() => {
                this.showDropdownMenu = false;
            }, 250);
        }
    }
    removeActiva() {
        let el = document.getElementById(`menu-item-${this.itemIndex}`);
        if (el) {
            el.classList.remove('item-hover');
        }
    }
    arrowDown() {
        if (this.itemIndex > this.optionsList.length - 1) {
            document
                .getElementById(`menu-item-${this.optionsList.length}`)
                ?.classList.add('item-hover');
            return;
        }
        this.itemIndex++;
        let el = document.getElementById(`menu-item-${this.itemIndex}`);
        if (el) {
            el.classList.add('item-hover');
        }
        this.scrollToElementById(`menu-item-${this.itemIndex}`);
    }

    arrowUp() {
        if (this.itemIndex == 1) {
            document.getElementById(`menu-item-1`)?.classList.add('item-hover');
            return;
        }
        this.itemIndex--;
        let el = document.getElementById(`menu-item-${this.itemIndex}`);
        if (el) {
            el.classList.add('item-hover');
        }
        this.scrollToElementById(`menu-item-${this.itemIndex}`);
    }

    scrollToElementById(id: string) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'end',
            });
        }
    }

    /////////////////////////// key events ////////////////////////////////////



    /////////////////////////// scroll get data ////////////////////////////////
    firstCall: boolean = true;
    scrollTo: number = 0;
    hasNextPage: boolean = true;
    async onScroll(event: any) {
        if (this.autocompleteDropdownConfig?.getAllData == false)
            if (
                event.target.offsetHeight + event.target.scrollTop >=
                event.target.scrollHeight - 800 &&
                this.firstCall
            ) {
                this.firstCall = false;
                this.optionsApi.pageIndex = this.optionsApi.pageIndex + 1;
                if (this.hasNextPage) {
                    await this.getList();
                    this.firstCall = true;
                } else {
                    this.firstCall = true;
                }
            }
    }

    private isPaginated = (object: PagingData<T> | T[]): object is PagingData<T> => {
        return 'pageData' in object;
    };
    /////////////////////////// scroll get data ////////////////////////////////

    handleKeydown(event: KeyboardEvent) {
        // if (event.key === 'Tab') {
        //     if (this.showDropdownMenu) {
        //         this.showInput = false;
        //     }
        //     this.itemIndex = 0;
        //     this.showDropdownMenu = false;
        // }
        switch (event.key) {
            case 'Tab':
                let el = document.getElementById(`menu-item-${this.itemIndex}`);
                if (el) {
                    el.classList.add('item-hover');
                }
                if (this.showDropdownMenu) {
                    this.showInput = false;
                }
                this.itemIndex = 1;
                this.showDropdownMenu = false;
                break;
            case 'ArrowDown':
                event.preventDefault();
                this.removeActiva();
                this.arrowDown();
                break;
            case 'ArrowUp':
                event.preventDefault();
                this.removeActiva();
                this.arrowUp();
                break;
        }
    }

    handleInputKey(event: KeyboardEvent) {
        switch (event.key) {
            case 'Tab':
                setTimeout(() => {
                    let el = document.getElementById(`menu-item-1`);
                    if (el) {
                        el.classList.add('item-hover');
                    }
                });
                break;
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.positionInterval);
    }
}
