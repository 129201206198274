import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
    DocumentsFpList,
    DropdownList,
} from 'src/app/shared/model/shared.model';
import { DocumentsService } from 'src/app/shared/services/documents.service';

interface Model {
    objectType: string;
    objectId: number;
    dropdownList: DropdownList[] | null;
}

@Component({
    selector: 'app-driver-asset-documents-modal',
    templateUrl: './documents-select-modal.component.html',
    styleUrls: ['./documents-select-modal.component.scss'],
})
export class DocumentsSelectModalComponent implements OnInit {
    allDocsList: DocumentsFpList[] = [];
    selectedDocsList: DocumentsFpList[] = [];
    isBusy: boolean = true;
    submitted: boolean = false;
    errorMessages: Set<string> = new Set();
    documentForm = this.fb.group({});
    // selectedDocsIds: Set<number> = new Set();
    constructor(
        public dialogRef: MatDialogRef<DocumentsSelectModalComponent>,
        @Inject(MAT_DIALOG_DATA) public dialogData: Model,
        private documentsService: DocumentsService,
        private fb: UntypedFormBuilder
    ) { }

    async ngOnInit(): Promise<void> {
        switch (this.dialogData.objectType) {
            case 'Driver':
                await this.getDriverDocs();
                break;
            case 'Truck':
                await this.getTruckDocs();
                break;
            case 'Trailer':
                await this.getTrailerDocs();
        }
        if (this.allDocsList.length == 0) this.cancel();
        this.isBusy = false;
        this.allDocsList.forEach((el) => {
            let docValue = el.documentType[0].toLowerCase() + el.documentType.slice(1)
            this.documentForm.addControl(
                `dropdown-${el.documentId}`,
                new UntypedFormControl(docValue)
            );

            this.documentForm.addControl(
                `checkbox-${el.documentId}`,
                new UntypedFormControl(false)
            );
        });
    }

    get f() {
        return this.documentForm.controls;
    }

    onDropdownChange(event: any, docId: number) {
        if (this.dialogData.dropdownList) {
            this.dialogData.dropdownList.forEach((dp) => (dp.hide = false));
            this.allDocsList.forEach((el) => {
                this.dialogData.dropdownList?.forEach((dp) => {
                    if (
                        this.f[`dropdown-${el.documentId}`].value == dp.value &&
                        dp.value != 'otherDocument'
                    ) {
                        dp.hide = true;
                    }
                });
            });
            this.selectedDocsList.find(
                (el) => el.documentId == docId
            )!.data.selectedType = this.f[`dropdown-${docId}`].value;
        }
    }

    cancel() {
        this.dialogRef.close(null);
    }

    onCheckbox(value: boolean, docId: number) {
        if (value) {
            if (this.selectedDocsList.find((el) => el.documentId == docId)) {
                return;
            } else {
                let item = this.allDocsList.find((el) => el.documentId == docId);
                if (item) {
                    let documentType = item.documentType[0].toLowerCase() + item.documentType.slice(1)
                    this.selectedDocsList.push({
                        ...item,
                        data: {
                            selectedType: documentType,
                            clonedFileId: 1,
                        },
                    });
                }
            }
        } else {
            if (this.selectedDocsList.find((el) => el.documentId == docId)) {
                this.selectedDocsList = this.selectedDocsList.filter(
                    (el) => el.documentId != docId
                );
            }
        }
    }

    async getDriverDocs() {
        const response = await this.documentsService.getDriverDocuments(
            this.dialogData.objectId
        );
        if (response.data) this.allDocsList = response.data;
    }
    async getTrailerDocs() {
        const response = await this.documentsService.getTrailerDocuments(
            this.dialogData.objectId
        );
        if (response.data) this.allDocsList = response.data;
    }
    async getTruckDocs() {
        const response = await this.documentsService.getTruckDocuments(
            this.dialogData.objectId
        );
        if (response.data) this.allDocsList = response.data;
    }

    async saveChanges() {
        this.submitted = true;
        try {
            for (let i = 0; i < this.selectedDocsList.length; i++) {
                let id = (
                    await this.documentsService.cloneDocument(
                        this.selectedDocsList[i].documentId
                    )
                ).data?.id;
                this.selectedDocsList[i].data.clonedFileId = id;
            }
            // this.selectedDocsList.forEach(async (el) => {});
            this.dialogRef.close(this.selectedDocsList);
        } catch (error: any) {
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        }
    }
}
