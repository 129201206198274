import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SharedTemplatesService<T> {

    constructor() { }

    $dialog_header: TemplateRef<{
        comp: T,
        title: string
    }> | null = null;

    $driver_tooltip: TemplateRef<T> | null = null;
    $background_screening_request_in_progress: TemplateRef<{title: string}> | null = null;
}
