<!-- Actions templeate shared -->
<!-- ************************ -->
<ng-template #actions let-card="card" let-cardComp="cardComp">
    <ng-container *ngIf="cardComp?.cardConfig; else table">
        <ng-container *ngIf="
                cardComp?.dataTableConfig?.cardActionIcon != undefined;
                else noImage
            ">
            <img src="/assets/images/{{ cardComp?.cardConfig?.cardActionIcon }}" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false" class="dots-image" />
        </ng-container>
        <ng-template #noImage>
            <img src="/assets/images/dots.svg" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"
                class="dots-image" />
        </ng-template>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <ng-container *ngFor="let action of cardComp.cardConfig.actionsList">
                <li *ngIf="action.hideAction ? !action.hideAction() : true" class="dropdown-item body-short-2" (click)="
                        action.functionType == functionTypeEnum.remove
                            ? cardComp.removeRow(card)
                            : action?.actionFunc(card)
                    ">
                    <img class="drop-down-item-icon" *ngIf="action.icon !== undefined"
                        src="/assets/images/{{ action.icon }}" /><span class="action-label">{{
                        action.label | translate
                        }}</span>
                </li>

            </ng-container>
        </ul>
    </ng-container>
    <ng-template #table>
        <img src="/assets/images/{{
                cardComp?.dataTableConfig.cardActionIcon
                    ? cardComp?.dataTableConfig.cardActionIcon
                    : 'dots.svg'
            }}" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false" class="dots-image"
            [class.d-none]="cardComp.checkActionsNumber(card)" />
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <ng-container *ngFor="let action of cardComp.dataTableConfig.actionsList">
                <li *ngIf="!action.hideAction()" [class.d-none]="action.hideAction ? action.hideAction() : false"
                    class="dropdown-item body-short-2" (click)="
                    action.functionType == functionTypeEnum.remove
                        ? cardComp.removeRow(card)
                        : action?.actionFunc(card)
                ">
                    <!-- <img class="drop-down-item-icon" *ngIf="action.icon !== undefined"
                        src="/assets/images/{{ action.icon }}" /><span class="action-label">{{
                        action.label | translate
                        }}</span> -->
                    <i class="{{action.icon}} drop-down-item-icon" [class.danger-icon]="action.isDanger"></i>
                    <span class="action-label" [class.danger-action]="action.isDanger">
                        {{ action.label| translate }}</span>
                </li>
            </ng-container>
        </ul>
        <!-- <mat-menu #menu="matMenu" xPosition="before">
            <button mat-menu-item class="body-short-2"
                [class.d-none]="action.hideAction ? action.hideAction() : false"
                *ngFor="let action of cardComp.dataTableConfig.actionsList"
                 (click)="
                action.functionType == functionTypeEnum.remove
                    ? cardComp.removeRow(card)
                    : action?.actionFunc(card)
            ">
                <img class="drop-down-item-icon"
                    *ngIf="action.icon !== undefined"
                    src="/assets/images/{{ action.icon }}" />
                <span class="action-label"
                    [class.danger-action]="action.isDanger">{{ action.label
                    |translate }}</span>
            </button>
        </mat-menu> -->
    </ng-template>
</ng-template>
<!-- ************************ -->

<!--Driver Card-->

<ng-template #driver_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card driver-card">
        <div class="row justify-content-between">
            <div class="col-10">
                <h4 class="driver-card-head">
                    {{ card.firstName + " " + card.lastName }}
                </h4>
            </div>
            <div class="col-1 card-actions" *ngIf="cardComp.dataTableConfig.allowCardAction">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <span class="body-short-1">
                    <img class="status-icon" *ngIf="getDriverStatus(card.employeeStatus).icon" src="/assets/images/{{
                        getDriverStatus(card.employeeStatus).icon
                    }}" />
                    {{ getDriverStatus(card.employeeStatus).label | translate }}
                </span>
            </div>
            <div class="col-12 mt-2">
                <span class="driver-details">{{card.companyUserCode}}</span>
            </div>
            <div class="col-12" *ngIf="card.cdlExpirationDate">
                <div class="driver-details">
                    {{card.cdlExpirationDate | date }}
                </div>
            </div>
            <div class="col-12" *ngIf="card.medicalCardExpiryDate">
                <div class="driver-details mb-0">
                    {{(card.medicalCardExpiryDate | date)}}
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Residence Card-->

<ng-template #residence_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card residence_card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h4 class="residence-card-head">
                    {{ card.city }}
                </h4>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12 mb-1">
                <div class="address">
                    {{ card.addressLine1 }}
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6 col-12 d-flex">
                    <div class="residence-details">
                        {{ card.startDate | date : "YYYY" }}&nbsp;-&nbsp;
                    </div>
                    <div class="residence-details">
                        {{ card.endDate | date : "YYYY" }}
                    </div>
                </div>
                <div class="col-xl-6 col-12 residence-date d-flex">
                    <div class="residence-details">
                        {{ card.startDate | date : "MMM, y" }}&nbsp;-&nbsp;
                    </div>
                    <div class="residence-details">
                        {{ card.endDate | date : "MMM, y" }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Experience Card-->

<ng-template #experience_card let-card="card" let-cardComp="cardComp">
    <div class="experience-card custom-card">
        <div class="row experience-card-head justify-content-between">
            <div class="col-10">
                <h4 class="heading-5 exp-title">
                    {{ card.schoolOrExperienceType }}
                </h4>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12 mb-1">
                <div class="school-name">
                    {{ card.schoolName ? card.schoolName : card.equipmentType }}
                </div>
            </div>
            <div class="col-8 d-flex">
                <div class="exp-details">
                    {{ card.startDate | date : "MM/YYYY" }}&nbsp;-&nbsp;
                </div>
                <div class="exp-details">
                    {{ card.endDate | date : "MM/YYYY" }}
                </div>
            </div>
            <div class="col-8">
                <div class="exp-details">{{ card.milesDriven }}&nbsp;mi</div>
            </div>
        </div>
    </div>
</ng-template>

<!--Employment Card-->

<ng-template #employment_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card emp-card">
        <div class="row justify-content-between justify-content-between">
            <div class="col-10">
                <h4 class="emp-card-head">
                    {{ card.companyName }}
                </h4>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12">
                <div class="designation">
                    {{ card.designation }}
                </div>
            </div>
            <div class="col-8">
                <div class="emp-details">
                    {{ card.startDate | date }}&nbsp;-&nbsp;{{
                    card.endDate | date
                    }}
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Violation Card-->

<ng-template #violation_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card violation-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h4 class="violation-card-head">
                    {{ card.description }}
                </h4>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12">
                <div class="violation-date">
                    {{ card.violationDate | date : "MM/y" }}
                </div>
            </div>
            <div class="col-8">
                <div class="violation-details">
                    {{ card.violationLocation }}
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Incident Card-->

<ng-template #incident_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card incident-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h4 class="incident-card-head">
                    {{ card.incidentDate | date : "MM/y" }}
                </h4>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12 vehicleType">
                <div class="incident-type">
                    {{ card.vehicleType }}
                </div>
            </div>
            <div class="col-8">
                <div class="incident-details">
                    {{ card.fatalities }}&nbsp;Fatalities
                </div>
                <div class="incident-details">
                    {{ card.injuries }}&nbsp;Injuries
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!--Job Opening Card-->
<ng-template #job_openings let-card="card" let-cardComp="cardComp">
    <div class="custom-card jop-openings-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h5 class="heading-5">
                    {{ card.jobTitle }}
                </h5>
            </div>

            <div class="col-1 card-actions" *ngIf="cardComp.dataTableConfig.allowCardAction">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3 mb-2">
                <span class="body-short-1 job-status {{
                        getJobStatus(card.jobStatus).class
                    }}">{{ getJobStatus(card.jobStatus).label | translate }}</span>
            </div>
        </div>
        <div class="row custom-card-body mt-3">
            <div class="col-12 mb-1">
                <div class="body-short-1 jop-desc">
                    {{ card.jobDescription }}
                </div>
            </div>
        </div>
        <div class="row card-fotter mt-3">
            <div class="col-4">
                <span class="label-1">{{ "APPLIED" | translate }}</span>
                <p class="body-short-2">{{ card.driverApplied }}</p>
            </div>
            <div class="col-4">
                <span class="label-1">{{ "REQUIRED" | translate }}</span>
                <p class="body-short-2">{{ card.driverRequired }}</p>
            </div>
            <div class="col-4">
                <span class="label-1">{{ "HIRED" | translate }}</span>
                <p class="body-short-2">{{ card.driverHired }}</p>
            </div>
        </div>
    </div>
</ng-template>

<!--Previous Employer Card-->

<ng-template #previous_employers_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card previous_employers_card">
        <div class="row mt-2 justify-content-between">
            <div class="col-10">
                <h5 class="heading-5">
                    {{ card.companyName }}
                </h5>
            </div>
            <div class="col-1 card-actions" *ngIf="cardComp.cardConfig.allowCardAction">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row info mt-1">
            <div class="col-12">
                <p>{{ card.supervisorEmail }}</p>
                <p>
                    {{
                    card.supervisorPhoneNo
                    ? phoneNumberService.phoneNumberFromServer(
                    card.supervisorPhoneNo
                    )
                    : ""
                    }}
                </p>
                <p>
                    {{ "ATTEMPTS_COUNT" | translate }}:
                    {{ card.contactAttempts }}
                </p>
                <p *ngIf="card.receivedDate; else lastAttemptDate">
                    {{ "RECEIVED_DATE" | translate }} :
                    {{ card.receivedDate | date }}
                </p>
                <ng-template #lastAttemptDate>
                    <p>
                        {{ "LAST_ATTEMPT_DATE" | translate }} :
                        {{ card.lastAttemptDate | date }}
                    </p>
                </ng-template>
            </div>
        </div>
        <div class="row d-flex">
            <span class="comment-box" *ngIf="card.comments">
                {{ card.comments }}
            </span>
        </div>
        <div class="row mt-3">
            <span *ngIf="card.requestStatus" class="status-box" [class.received]="card.requestStatus == 'Received'"
                [class.requested]="card.requestStatus == 'Requested'">{{ card.requestStatus }}</span>
        </div>
    </div>
</ng-template>

<!--CDL Card-->
<ng-template #cdl_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card cdl_card">
        <div class="row custom-card-head">
            <div class="col-9">
                <h4 class="cdl-card-head">
                    {{ card.issuingState }}
                </h4>
            </div>
            <div class="col-3 card-actions d-flex justify-content-end" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <div class="active" *ngIf="card.isActive">
                    <img class="active-icon" src="/assets/images/green-check.svg" alt="" />
                    Active
                </div>
                <div class="actions-icon">
                    <ng-container *ngTemplateOutlet="
                            actions;
                            context: {
                                card: card,
                                cardComp: cardComp
                            }
                        "></ng-container>
                </div>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12 mb-1">
                <div class="address">
                    {{ card.cdlNumber }}
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4 col-12 d-flex">
                    <div class="residence-details">
                        {{ ("CLASS" | translate) + " " + card.cdlType }}
                    </div>
                </div>
                <div class="col-xl-8 col-12 residence-date d-flex">
                    <div class="residence-details">
                        {{ "VALID_UNTIL" | translate }}:
                        {{ card.expirationDate | date : "M/dd/y" }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Truck Card-->

<ng-template #truck_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card truck-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.assetCode }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12">
                <span class="body-short-1" *ngIf="card.model || card.modelYear">{{ card.model ?? "" + "," +
                    card.modelYear ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.plateNumber ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{
                    sharedStatusService.getAssetStatus(card.assetStatus).label
                    | translate
                    }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.vin ?? "" }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Trailer Card-->

<ng-template #trailer_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card trailer-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.assetCode }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12">
                <span class="body-short-1">{{ card.plateNumber ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{
                    sharedStatusService.getAssetStatus(card.assetStatus).label
                    | translate
                    }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.vin ?? "" }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Company Address Card-->

<ng-template #company_address_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card company_address_card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6 title" [class.not-active]="!card.isActive">
                    <img src="assets/images/main-address-icon.svg" *ngIf="card.isMainAddress" />
                    {{ card.addressType }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12">
                <span class="body-short-1">{{
                    (card.addressLine1 ?? "") + " " + (card.addressLine2 ?? "")
                    }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.city ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.stateCode ?? "" }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Company Contact Card-->

<ng-template #company_contact_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card company-contact-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.firstName + " " + card.lastName }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12">
                <span class="body-short-1">{{ card.employeeId ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.title ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.email ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{
                    card.phoneNumber
                    ? phoneNumberService.phoneNumberFromServer(
                    card.phoneNumber
                    )
                    : ""
                    }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Company Other Documents Card-->

<ng-template #company_other_documents_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card company-other-documents-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.documentType }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12">
                <span class="body-short-1">{{ card.description ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{
                    card.issueDate ? (card.issueDate | date : environment.dateFormat) : ""
                    }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Supplier Contact Card-->

<ng-template #supplier_contact_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card supplier-contact-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.firstName + " " + card.lastName }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12">
                <span class="body-short-1">{{ card.title ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.email ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{
                    card.phoneNumber
                    ? phoneNumberService.phoneNumberFromServer(
                    card.phoneNumber
                    )
                    : ""
                    }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{
                    card.faxNumber
                    ? phoneNumberService.phoneNumberFromServer(
                    card.faxNumber
                    )
                    : " "
                    }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Inspection Report Card-->

<ng-template #inspection_report_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card inspection-report-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.reportNumber }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12">
                <div class="summary-status {{ card.statusObj.class }}">
                    {{ card.statusObj.label | translate }}
                </div>
                <span class="body-short-1">{{ card.driver.name ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{
                    card.dueDate ? (card.dueDate | date) : ""
                    }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.email ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{
                    card.phoneNumber
                    ? phoneNumberService.phoneNumberFromServer(
                    card.phoneNumber
                    )
                    : ""
                    }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- incidents card-->

<ng-template #incidents_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card incident-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.reportNumber }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12">
                <span class="body-short-1">{{ card.onDutyDriver ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.location ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{
                    card.reportDate ? (card.reportDate | date : environment.dateFormat) : ""
                    }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.assetTruck ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.assetTrailers ?? "" }}</span>
            </div>
            <div class="col-12 mt-3">
                <span class="status label-1 {{
                        getIncidentsStatus(
                            incidentsApiService.incidentStatus$.getValue()
                        ).class
                    }}">{{
                    getIncidentsStatus(
                    incidentsApiService.incidentStatus$.getValue()
                    ).label | translate
                    }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Drug & Alcohol Test card-->

<ng-template #drug_test_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card drug-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.testType }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12 drug-status-column">
                <img class="status-icon" src=" {{
                        card.testStatus == 'Open'
                            ? '/assets/images/green.svg'
                            : '/assets/images/gray.svg'
                    }} " alt="" />
                <span class="body-short-1">{{ card.testStatus ?? "" }}</span>
            </div>
            <div class="col-12 mt-2">
                <span class="body-short-1">{{
                    card.date
                    ? (card.date
                    | date : environment.dateTimeFormat)
                    : ""
                    }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Allow Edit Button -->
<ng-template #allowEditButton let-allowEdit="allowEdit" let-comp="comp">
    <ng-container *ngIf="!allowEdit && comp.isEditGranted">
        <div class="app-edit-button-container">
            <button type="button" class="app-edit-button" style="padding-top: 3px;" (click)="comp.allowEditFrom()"
                data-toggle="tooltip" data-placement="bottom" title="{{ 'EDIT' | translate }}">
                <i class="icon-edit-05 icon-s"></i>
            </button>
        </div>
    </ng-container>
</ng-template>


<!-- Insurance Card-->

<ng-template #insurance_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.policyNumber }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="custom-card-body">
            <div class="row mt-3">
                <div class="col-12">
                    <span class="body-short-1">{{
                        card.insurancePolicyCoverages ? insurancePolicyCoverages(card) : ""
                        }}</span>
                </div>
                <div class="col-12">
                    <span class="body-short-1">{{
                        card.expirationDate ? (card.expirationDate | date : environment.dateFormat) : ""
                        }}</span>
                </div>
                <div class="col-12">
                    <span class="body-short-1">{{
                        card.insuranceAgency ? card.insuranceAgency : ""
                        }}</span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Truck Card-->

<ng-template #endrosments_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card endrosments-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.endorsementCode }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12 status">
                <span class="endorsement-status" [class.closed-status]="card?.endorsementStatus == 'Closed'"
                    [class.in-progress-status]="card?.endorsementStatus == 'InProgress'">{{card?.endorsementStatus ==
                    "InProgress" ? "In Progress" : card?.endorsementStatus}}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.insuranceAgency ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{ card.sentOnDate | date : environment.dateFormat }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- Claims Card-->

<ng-template #claims_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card claim-card">
        <div class="row custom-card-head justify-content-between">
            <div class="col-10">
                <h6 class="heading-6">
                    {{ card.claimType }}
                </h6>
            </div>
            <div class="col-1 card-actions" *ngIf="
                    cardComp.cardConfig?.allowCardAction
                        ? cardComp.cardConfig?.allowCardAction
                        : cardComp.dataTableConfig.allowCardAction
                ">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row custom-card-body">
            <div class="col-12 mb-2">
                <!-- <div class="summary-status {{ card.statusObj.class }}">
                    {{ card.statusObj.label | translate }}
                </div>
                <span class="body-short-1">{{ card.driver.name ?? "" }}</span> -->
                <div class="label-1 status" [class.closed]="card.claimStatus == 'Closed'">
                    {{
                    card.claimStatus
                    }}
                </div>
            </div>
            <div class="col-12">
                <span class="body-short-1">{{
                    card.claimNumber ? card.claimNumber : ""
                    }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1 label-color">{{ card.claimAmount ?? "" }}</span>
            </div>
            <div class="col-12">
                <span class="body-short-1 label-color">{{
                    card.insuranceName ? card.insuranceName : ""
                    }}</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- employee_card -->
<ng-template #employee_card let-card="card" let-cardComp="cardComp">
    <div class="custom-card driver-card">
        <div class="row justify-content-between">
            <div class="col-10">
                <h4 class="driver-card-head">
                    {{ card.firstName + " " + card.lastName }}
                </h4>
            </div>
            <div class="col-1 card-actions" *ngIf="cardComp.dataTableConfig.allowCardAction">
                <ng-container *ngTemplateOutlet="
                        actions;
                        context: {
                            card: card,
                            cardComp: cardComp
                        }
                    "></ng-container>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <span class="body-short-1">
                    <img class="status-icon" *ngIf="getDriverStatus(card.employeeStatus).icon" src="/assets/images/{{
                        getDriverStatus(card.employeeStatus).icon
                    }}" />
                    {{ getDriverStatus(card.employeeStatus).label | translate }}
                </span>
            </div>
            <div class="col-12 mt-2">
                <span class="driver-details">{{card.companyUserCode}}</span>
            </div>
            <div class="col-12" *ngIf="card.email">
                <div class="driver-details">
                    {{card.email}}
                </div>
            </div>
            <div class="col-12" *ngIf="card.medicalCardExpiryDate">
                <div class="driver-details">
                    {{card.medicalCardExpiryDate |date}}
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #dialog_header let-comp="comp" let-title="title">
    <div class="d-flex">
        <div class="dialog-title">{{ title | translate}}</div>
        <button class="close-button" (click)="comp.close()">
            <i class="icon-x-close icon-m"></i>
        </button>
    </div>
</ng-template>

<ng-template #driver_tooltip let-data="data">
    <div class="row" *ngIf="data">
        <div class="col-md-6 col-12">
            <div class="heading-7">{{ "DRIVER_ID" | translate }}</div>
            <span class="body-long-1">{{ data.companyUserCode }}</span>
        </div>
        <div class="col-md-6 col-12">
            <div class="heading-7">{{ "CDL_NUMBER" | translate }}</div>
            <span class="body-long-1">{{ data.cdlNumber }}</span>
        </div>
    </div>
    <div class="row mt-3" *ngIf="data">
        <div class="col-md-6 col-12">
            <div class="heading-7">{{ "FIRST_NAME" | translate }}</div>
            <span class="body-long-1">{{ data.firstName }}</span>
        </div>
        <div class="col-md-6 col-12">
            <div class="heading-7">{{ "LAST_NAME" | translate }}</div>
            <span class="body-long-1">{{ data.lastName }}</span>
        </div>
    </div>
    <div class="row mt-4 d-flex justify-content-end">
        <div class="col-md-5 col-12">
            <button type="button" class="secondary-button-large" (click)="goToDriver(data.id)">
                {{ "NAVIGATE_TO" | translate }}
            </button>
        </div>
    </div>
</ng-template>

<ng-template #background_screening_request_in_progress let-title="title">
    <div class="background-screening-request-in-progress">
        <p>
            {{title}} Request In Progress
            <i class="icon-loading-02"></i>
        </p>
    </div>
</ng-template>