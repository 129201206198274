import { PagingData } from './../../core/models/api-response.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { environment } from 'src/environments/environment';
import { GetDataOptions, TimeZone } from '../model/shared.model';
import { SignatureDetials, SigningDetials } from 'src/app/core/models/shared.model';
import { lastValueFrom } from 'rxjs';

const APIURL = `${environment.apiUrl}/`;

@Injectable({
    providedIn: 'root',
})
export class SharedApiService {
    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'json',
        }),
        responseType: 'json' as 'json',
    };
    constructor(private http: HttpClient) {}

    async getTimeZoneList(
        options: GetDataOptions
    ): Promise<ApiResponse<PagingData<TimeZone>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<TimeZone>>>(
                APIURL +
                    `api/DateTime/TimeZone?pageSize=${
                        options?.pageSize
                    }&pageIndex=${
                        options?.pageIndex ? options?.pageIndex : 0
                    }&orderBy=${
                        options?.sort ? options?.sort : ''
                    }&searchString=${options?.searchString}`,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else
            return {
                success: false,
                data: null,
            };
    }

    async getSignatureDetials(
        objectId: number
    ): Promise<ApiResponse<SignatureDetials>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<SignatureDetials>>(
                `${APIURL}cmp/SigningInformation/${objectId}`
            )
            );
        return response;
    }

    async getSigningDetials(
        dataName: string,
        objectId: number
    ): Promise<ApiResponse<SigningDetials[]>> {
        const response = await lastValueFrom(this.http
            .get<ApiResponse<SigningDetials[]>>(
                `${APIURL}usr/Signing?dataName=${dataName}&objectId=${objectId}`
            )
            );
        return response;
    }
}
