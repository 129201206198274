import { SharedTemplatesService } from '../../services/shared-templates.service';
import { Router } from '@angular/router';
import { InsuranceList } from '../../../modules/insurance/models/insurance-policy.model';
import { IncidentsApiService } from '../../../modules/incidents/services/incidents-api.service';
import { PhoneNumberService } from 'src/app/shared/services/phone-number.service';
import { SharedStatusService } from '../../services/shared-status.service';
import { SharedService } from '../../services/shared.service';
import { Component, OnInit, ElementRef, ViewChild, TemplateRef } from '@angular/core';
import { FunctionTypeEnum } from '../../model/app-table.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-shared-templates',
    templateUrl: './shared-templates.component.html',
    styleUrls: ['./shared-templates.component.scss'],
})
export class SharedTemplatesComponent<T> implements OnInit {
    environment = environment;
    @ViewChild('driver_card', { static: true }) driver_card: TemplateRef<any> | null = null;
    @ViewChild('experience_card', { static: true })
    experience_card: TemplateRef<any> | null = null;
    @ViewChild('residence_card', { static: true })
    residence_card: TemplateRef<any> | null = null;
    @ViewChild('employment_card', { static: true })
    employment_card: TemplateRef<any> | null = null;
    @ViewChild('violation_card', { static: true })
    violation_card: TemplateRef<any> | null = null;
    @ViewChild('incident_card', { static: true })
    incident_card: TemplateRef<any> | null = null;
    @ViewChild('job_openings', { static: true })
    job_openings: TemplateRef<any> | null = null;
    @ViewChild('previous_employers_card', { static: true })
    previous_employers_card: TemplateRef<any> | null = null;
    @ViewChild('cdl_card', { static: true })
    cdl_card: TemplateRef<any> | null = null;
    @ViewChild('truck_card', { static: true })
    truck_card: TemplateRef<any> | null = null;
    @ViewChild('trailer_card', { static: true })
    trailer_card: TemplateRef<any> | null = null;
    @ViewChild('company_address_card', { static: true })
    company_address_card: TemplateRef<any> | null = null;
    @ViewChild('company_contact_card', { static: true })
    company_contact_card: TemplateRef<any> | null = null;
    @ViewChild('supplier_contact_card', { static: true })
    supplier_contact_card: TemplateRef<any> | null = null;
    @ViewChild('company_other_documents_card', { static: true })
    company_other_documents_card: TemplateRef<any> | null = null;
    @ViewChild('inspection_report_card', { static: true })
    inspection_report_card: TemplateRef<any> | null = null;
    @ViewChild('incidents_card', { static: true })
    incidents_card: TemplateRef<any> | null = null;
    @ViewChild('drug_test_card', { static: true })
    drug_test_card: TemplateRef<any> | null = null;
    @ViewChild('allowEditButton', { static: true })
    allowEditButton: TemplateRef<any> | null = null;
    @ViewChild('insurance_card', { static: true })
    insurance_card: TemplateRef<any> | null = null;
    @ViewChild('endrosments_card', { static: true })
    endrosments_card: TemplateRef<any> | null = null;
    @ViewChild('truck_tooltip', { static: true })
    truck_tooltip: TemplateRef<any> | null = null;
    @ViewChild('claims_card', { static: true })
    claims_card: TemplateRef<any> | null = null;
    @ViewChild('employee_card', { static: true })
    employee_card: TemplateRef<any> | null = null;
    @ViewChild('driver_tooltip', { static: true })
    driver_tooltip: TemplateRef<any> | null = null;
    @ViewChild('background_screening_request_in_progress', { static: true })
    background_screening_request_in_progress: TemplateRef<any> | null = null;

    @ViewChild('dialog_header', { static: true })
    dialog_header: TemplateRef<{
        comp: T,
        title: string
    }> | null = null;
    constructor(
        private shared: SharedService,
        public sharedStatusService: SharedStatusService,
        public phoneNumberService: PhoneNumberService,
        public incidentsApiService: IncidentsApiService,
        private router: Router,
        private sharedTemplatesService: SharedTemplatesService<T>
    ) { }

    ngOnInit(): void {
        this.shared.$driver_card = this.driver_card;
        this.shared.$experience_card = this.experience_card;
        this.shared.$residence_card = this.residence_card;
        this.shared.$employment_card = this.employment_card;
        this.shared.$violation_card = this.violation_card;
        this.shared.$incident_card = this.incident_card;
        this.shared.$job_openings = this.job_openings;
        this.shared.$previous_employers_card = this.previous_employers_card;
        this.shared.$cdl_card = this.cdl_card;
        this.shared.$truck_card = this.truck_card;
        this.shared.$trailer_card = this.trailer_card;
        this.shared.$company_address_card = this.company_address_card;
        this.shared.$company_contact_card = this.company_contact_card;
        this.shared.$supplier_contact_card = this.supplier_contact_card;
        this.shared.$inspection_report_card = this.inspection_report_card;

        this.shared.$company_other_documents_card = this.company_other_documents_card;
        this.shared.$incidents_card = this.incidents_card;
        this.shared.$drug_test_card = this.drug_test_card;
        this.shared.$allowEditButton = this.allowEditButton;
        this.shared.$insuranceCard = this.insurance_card;
        this.shared.$endrosments_card = this.endrosments_card;
        this.shared.$claims_card = this.claims_card;
        this.shared.$employee_card = this.employee_card;
        this.sharedTemplatesService.$dialog_header = this.dialog_header;
        this.sharedTemplatesService.$driver_tooltip = this.driver_tooltip;
        this.sharedTemplatesService.$background_screening_request_in_progress = this.background_screening_request_in_progress;
    }

    public get functionTypeEnum(): typeof FunctionTypeEnum {
        return FunctionTypeEnum;
    }

    getJobStatus(key: string) {
        switch (key) {
            case "Post":
                return {
                    label: "OPEN_POST",
                    class: 'succsess'
                }
            case "Processing":
                return {
                    label: "PROCESSING",
                    class: 'warning'
                }
            case 'Closed':
                return {
                    label: "CLOSED",
                    class: 'closed'
                }
            case 'Draft':
                return {
                    label: "OPEN_DRAFT",
                    class: 'info'
                }
            case 'Archived':
                return {
                    label: "ARCHIVED",
                    icon: 'closed'
                }
            default:
                return {
                    label: 'No Status',
                    icon: ''
                }
        }
    }

    getIncidentsStatus(key: string) {
        switch (key) {
            case 'Closed':
                return {
                    label: 'CLOSED',
                    class: 'green',
                };
            case 'ReadyForProcessing':
                return {
                    label: 'READY_FOR_PROCESSING',
                    class: 'yellow',
                };
            case 'RemovalRequested':
                return {
                    label: 'REMOVAL_REQUESTED',
                    class: 'blue-gray',
                };
            case 'NewIncident':
                return {
                    label: 'NEW_INCIDENT',
                    class: 'red',
                };
            case 'InProgress':
                return {
                    label: 'IN_PROGRESS',
                    class: 'green-gray',
                };
            case 'Removed':
                return {
                    label: 'REMOVED',
                    class: 'blue-gray2',
                };
            default:
                return {
                    label: '',
                    icon: '',
                };
        }
    }

    insurancePolicyCoverages(item: InsuranceList) {
        let label = "";
        item.insurancePolicyCoverages.forEach((e, i) => {
            label = label + " " + e.insuranceType + (item.insurancePolicyCoverages.length - 1 != i ? ", " : " ");
        })
        return label;
    }

    goToTruck(id: number) {
        this.router.navigate([`trucks/truck/${id}/view/basic-information`]);
    }

    getDriverStatus(key: string) {
        switch (key) {
            case 'Active':
                return {
                    label: 'ACTIVE',
                    icon: 'green.svg',
                };
            case 'InActive':
                return {
                    label: 'Inactive',
                    icon: 'red.svg',
                };
            default:
                return {
                    label: '',
                    icon: '',
                };
        }
    }

    goToDriver(id: number) {
        // this.router.navigate([`driver/${id}/view/personal-info`]);
        let url = `${environment.webUrl}/driver/${id}/view/personal-info`
        window.open(url, '_blank');
    }
}
