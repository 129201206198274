import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { lastValueFrom } from 'rxjs';
import { SelectCompanyComponent } from 'src/app/shared/components/dialogs/select-company/select-company.component';
import { ContextService } from 'src/app/shared/services/context.service';

@Component({
    selector: 'app-no-permissions',
    templateUrl: './no-permissions.component.html',
    styleUrls: ['./no-permissions.component.scss']
})
export class NoPermissionsComponent {

    employeeCompanies: number = 0;
    constructor(
        private contextService: ContextService,
        private router: Router,
        public dialog: MatDialog,
        private spinner: NgxSpinnerService

    ) {
        let currentCompanyId = this.contextService.companyId$.getValue();
        if (!this.contextService.isSysAdmin() && currentCompanyId == 0) {
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: 'no-permissions' } });
        }
        this.employeeCompanies = this.contextService.employeeCompanies$.getValue().length;
        const PERMISSIONS_KEY = 'user-permissions';
        const permissionList = localStorage.getItem(PERMISSIONS_KEY)
            ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY) ?? '')
            : null;
        if (permissionList && permissionList.userResourcesPermissions.length > 1) {
            this.router.navigate(['/home'])
        }
    }

    contact() {
        let email = this.contextService.getCompanyEmail();
        window.location.href = `mailto:${email}`;
    }

    async selectCompanyDialog() {
        let dialogRef = this.dialog.open(SelectCompanyComponent, {
            disableClose: true,
            autoFocus: false,
            width: '35rem',
            data: {
                closeButton: true,
            },
            // height: '90rem'
        });
        let result = await lastValueFrom(dialogRef.afterClosed());
        if (result == 'close') {
            return;
        }
        this.spinner.show();
        await this.contextService.init();
        this.router.navigateByUrl('home');
        setTimeout(() => {
            this.spinner.hide();
        });
    }

}
