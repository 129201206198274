// import { Component } from '@angular/core';

// @Component({
//   selector: 'app-file-upload-box',
//   standalone: true,
//   imports: [],
//   templateUrl: './file-upload-box.component.html',
//   styleUrl: './file-upload-box.component.scss'
// })
// export class FileUploadBoxComponent {

// }

import { Observable, interval, lastValueFrom } from 'rxjs';
import {
    Component,
    OnInit,
    EventEmitter,
    Input,
    forwardRef,
    Output,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    AbstractControl,
    ControlValueAccessor,
    NG_VALIDATORS,
    NG_VALUE_ACCESSOR,
    ValidationErrors,
    Validator,
} from '@angular/forms';
import { ImageService } from '../../services/image.service';
import { FileService } from '../../services/file.service';
import { MatDialog } from '@angular/material/dialog';
import { SignatureDetailsComponent } from '../signature-details/signature-details.component';
export interface uploadFileRrsponse {
    sucssus: boolean;
    error: string;
}

@Component({
    selector: 'app-file-upload-box',
    templateUrl: './file-upload-box.component.html',
    styleUrl: './file-upload-box.component.scss',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => FileUploadBoxComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => FileUploadBoxComponent),
        },
    ],
})
export class FileUploadBoxComponent
    implements OnInit, ControlValueAccessor, Validator {
    @ViewChild('fileUploadInput') fileUploadInput: ElementRef | null = null;
    @Input() id: string = '';
    @Input() label: string = '';
    @Input() labelAfterUpload: string = '';
    @Input() readonly: boolean = false;
    fileId: number | null = null;
    @Input() fileType: string[] | null = null;
    @Input() hideReupload: boolean = false;
    @Input() hideDownload: boolean = false;
    @Input() hideDelete: boolean = false;
    @Input() disabledReupload: boolean = false;
    @Input() disabledDownload: boolean = false;
    @Input() disabledDelete: boolean = false;
    @Input() subtitle: string | null = null;
    @Input() isRequired: boolean = false;
    @Input() isInfoRequired: boolean = false;
    @Input() viewMode: boolean = false;
    @Input() value: number | null = null;
    @Input() signatureId: number | null = null;
    @Input() tooltip: string | null = null;
    @Output() valueChange: EventEmitter<{
        previousFileId: number | null;
        newFileId: number | null;
    }> = new EventEmitter();

    onChange = (fileId: number | null) => { };
    onTouched = () => { };
    touched = false;
    disabled = false;

    private uploadedFileType: string = '';
    counter: number = 0;
    isBusy: boolean = false;
    constructor(
        private fileService: FileService,
        private translate: TranslateService,
        private imageService: ImageService,
        private matDialog: MatDialog
    ) { }

    isInArray(value: any, array: string[]) {
        return array.indexOf(value) > -1;
    }

    validate(c: AbstractControl): ValidationErrors | null {
        if (this.fileType != null) {
            if (
                this.uploadedFileType &&
                !this.isInArray(this.uploadedFileType, this.fileType)
            ) {
                return {
                    fileType: {
                        availableOptions: this.fileType,
                        currentType: this.uploadedFileType,
                    },
                };
            }
        }

        return null;
    }

    registerOnValidatorChange?(fn: () => void): void {
    }

    writeValue(fileid: any): void {
        this.fileId = fileid;
        if (this.fileId == null && this.fileUploadInput?.nativeElement?.setValue)
            this.fileUploadInput?.nativeElement?.setValue('');
    }
    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    ngOnInit(): void {
        if (this.value) {
            this.writeValue(this.value);
        }
    }

    onSignaturedetails() {
        this.matDialog.open(SignatureDetailsComponent, {
            width: '41.6rem',
            disableClose: true,
            data: {
                objectId: this.signatureId,
            },
        });
    }

    getCounter(): Observable<number> {
        return interval(100);
    }

    async uploadFile($event: any) {
        this.isBusy = true;
        this.counter = 0;
        this.getCounter().subscribe(() => {
            if (this.counter < 99)
                this.counter++;
        });
        this.markAsTouched();
        let previousFileId = this.fileId;
        let file = $event.dataTransfer ? $event.dataTransfer.files[0] : $event.target.files[0];
        // if (!this.labelAfterUpload) {
        //     this.labelAfterUpload = $event.target.files[0].name
        // }
        let response: any;
        if (file) {
            const filename = file.name
            let last_dot = filename.lastIndexOf('.')
            let ext = filename.slice(last_dot + 1)
            this.uploadedFileType = ext;
            if (
                this.fileType == null ||
                this.isInArray(this.uploadedFileType, this.fileType)
            ) {
                if (file.type.includes('image')) {
                    let imageFile = await this.imageService.resizeImage(
                        file,
                        (await lastValueFrom(this.translate
                            .get(
                                this.labelAfterUpload
                                    ? this.labelAfterUpload
                                    : this.label
                            )
                        )) + '.png'
                    );
                    response = await this.fileService.saveFile(imageFile!);
                } else {
                    response = await this.fileService.saveFile(
                        $event.dataTransfer ? $event.dataTransfer.files[0] : $event.target.files[0]
                    );
                }
                this.counter = 100;
                setTimeout(() => {
                    this.isBusy = false;
                    this.fileId = response.id;
                    this.onChange(this.fileId);
                    this.valueChange.next({
                        previousFileId: previousFileId,
                        newFileId: this.fileId,
                    });
                }, 750);
            } else {
                this.counter = 100;
                this.isBusy = false;
                this.onChange(null);
            }
        }
    }

    async downloadFile(viewFileAfterDownload: boolean = false) {
        this.isBusy = true;
        this.counter = 0;
        this.getCounter().subscribe(() => {
            if (this.counter < 99)
                this.counter++;
        });
        await this.fileService.downloadFile(
            await lastValueFrom(this.translate
                .get(this.labelAfterUpload ? this.labelAfterUpload : this.label)),
            this.fileId,
            viewFileAfterDownload,
            false
        );
        this.counter = 100;
        setTimeout(() => {
            this.isBusy = false;
        }, 1000);
    }

    deleteFile() {
        // this.fileUploadInput!.nativeElement.value! = '';
        let previousFileId = this.fileId;
        this.markAsTouched();
        this.fileId = null;
        this.onChange(null);
        this.valueChange.next({
            previousFileId: previousFileId,
            newFileId: this.fileId,
        });
    }



    handleFileDrop(event: DragEvent) {
        if (event) {
            this.uploadFile(event)
        }
    }
}

