import { JobOpeningsApplications } from './../models/job-openings.model';
import { PagingData } from '../../../core/models/api-response.model';
import {
    InviteApplicant,
    JobOpening,
    JobSkills,
    Questions,
    JobBenefit,
    HiringDashboard,
    HiringApplicants,
    SendOfferLetter,
} from '../models/job-openings.model';
import { ContextService } from '../../../shared/services/context.service';
import {
    ApiResponse,
    ApiResponseAny,
} from 'src/app/core/models/api-response.model';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { environment } from 'src/environments/environment';

import { Subscription } from 'rxjs-compat/Subscription';
import { lastValueFrom } from 'rxjs';
import { GetDataOptions } from 'src/app/shared/model/shared.model';

const APIURL = `${environment.apiUrl}/cmp/`;

@Injectable({
    providedIn: 'root',
})
export class JobOpeningsApiService implements OnDestroy {
    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'json',
        }),
        responseType: 'json' as 'json',
    };
    companyId: number = 0;
    companyLocationId: number = 0;
    contextSub: Subscription | null = null;
    constructor(
        private http: HttpClient,
        private contextService: ContextService
    ) {
        this.companyId = this.contextService.getCompanyId();
        // this.companyLocationId = this.contextService.getCompanyLocationId();
        this.contextSub = this.contextService.companyId$.subscribe((res) => {
            this.companyId = res;
        });
        // this.contextService.companyLocationId$.subscribe((res) => {
        //     this.companyLocationId = res;
        // });
    }

    async getJobOpenings(
        options: GetDataOptions,
        isArchived: boolean
    ): Promise<ApiResponse<PagingData<JobOpening>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<JobOpening>>>(
                APIURL +
                `JobOpening/FpList?searchString=${options.searchString}`+
                `&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}&isArchived=${isArchived}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async deleteJopOpenings(id: number): Promise<ApiResponse<JobOpening>> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponse<JobOpening>>(
                APIURL +
                `JobOpening/${id}`,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async addJob(body: JobOpening): Promise<ApiResponse<JobOpening>> {
        let response = await lastValueFrom(this.http
            .post<ApiResponse<JobOpening>>(
                APIURL +
                `JobOpening`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async editJob(
        id: number,
        body: JobOpening
    ): Promise<ApiResponse<JobOpening>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<JobOpening>>(
                APIURL +
                `JobOpening/${id}`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getJobDetails(id: number): Promise<ApiResponse<JobOpening>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<JobOpening>>(
                APIURL +
                `JobOpening/${id}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getApplications(
        jobId: number,
        options: GetDataOptions
    ): Promise<ApiResponse<PagingData<JobOpeningsApplications>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<JobOpeningsApplications>>>(
                APIURL +
                `JobOpening/${jobId}/Application/FpList?searchString=${options.searchString}&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async inviteApplicant(
        jobId: number,
        body: InviteApplicant
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .post<ApiResponseAny>(
                APIURL +
                `JobOpening/${jobId}/Invite`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
            };
        }
    }

    async duplicateJob(jobId: number): Promise<ApiResponse<JobOpening>> {
        let response = await lastValueFrom(this.http
            .post<ApiResponse<JobOpening>>(
                APIURL + `JobOpening/${jobId}/Duplicate`,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getBenefits(): Promise<ApiResponse<PagingData<JobBenefit>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<JobBenefit>>>(
                APIURL + `JobOpeningAux/Benefit/FpList?pageIndex=0&pageSize=1234`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getSkills(): Promise<ApiResponse<PagingData<JobSkills>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<JobSkills>>>(
                APIURL + `JobOpeningAux/Skill/FpList?pageIndex=0&pageSize=1234`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getQuestions(jobId: number): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .get<ApiResponseAny>(
                APIURL + `JobOpening/${jobId}/Question`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async editQuestions(
        jobId: number,
        body: Questions[]
    ): Promise<ApiResponse<Questions>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<Questions>>(
                APIURL + `JobOpening/${jobId}/Question`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async addQuestions(
        jobId: number,
        body: Questions[]
    ): Promise<ApiResponse<Questions>> {
        let response = await lastValueFrom(this.http
            .post<ApiResponse<Questions>>(
                APIURL + `JobOpening/${jobId}/Question`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async deleteQuestion(
        jobId: number,
        questionId: number
    ): Promise<ApiResponse<Questions>> {
        let response = await lastValueFrom(this.http
            .delete<ApiResponse<Questions>>(
                APIURL +
                `JobOpening/${jobId}/Question/${questionId}`,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getJobHiringDashbord(
        id: number
    ): Promise<ApiResponse<HiringDashboard>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<HiringDashboard>>(
                APIURL + `JobOpening/${id}/Hiring/Dashboard`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getJobHiringApplicantsList(
        id: number,
        options: GetDataOptions
    ): Promise<ApiResponse<PagingData<HiringApplicants>>> {
        let response = await lastValueFrom(this.http
            .get<ApiResponse<PagingData<HiringApplicants>>>(
                APIURL +
                `JobOpening/${id}/Hiring/Application/FpList?searchString=${options.searchString}&pageIndex=${options.pageIndex}&pageSize=${options.pageSize}&orderBy=${options.sort}`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async sendOfferLetter(
        id: number,
        body: any
    ): Promise<ApiResponse<SendOfferLetter>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<SendOfferLetter>>(
                APIURL + `JobOpening/${id}/Hiring/SendOfferLetter`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async getHiringOfferTemplate(
        id: number
    ): Promise<ApiResponseAny> {
        let response = await lastValueFrom(this.http
            .get<ApiResponseAny>(
                APIURL + `JobOpening/${id}/Hiring/OfferTemplate?jobApplicationId=0`
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    async uploadContract(
        id: number,
        body: any
    ): Promise<ApiResponse<ApiResponseAny>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<ApiResponseAny>>(
                APIURL + `JobOpening/${id}/Hiring/Contract`,
                body,
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }
    async closeJob(
        id: number
    ): Promise<ApiResponse<ApiResponseAny>> {
        let response = await lastValueFrom(this.http
            .put<ApiResponse<ApiResponseAny>>(
                APIURL + `JobOpening/${id}/Close`,
                {},
                this.requestOptions
            ));
        if (response.success) {
            return response;
        } else {
            return {
                success: false,
                data: null,
            };
        }
    }

    ngOnDestroy(): void {
        if (this.contextSub) this.contextSub.unsubscribe();
    }
}
