import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-save-changes-v2',
    templateUrl: './save-changes-v2.component.html',
    styleUrl: './save-changes-v2.component.scss'
})
export class SaveChangesV2Component {

    constructor(
        private dialogRef: MatDialogRef<SaveChangesV2Component>,
    ) { }

    save() {
        this.dialogRef.close("save");
    }

    discard() {
        this.dialogRef.close("discard");
    }

    cancel() {
        this.dialogRef.close("cancel");
    }

}
