import { BackgroundScreeningOrdersModule } from './modules/background-screening-orders/background-screening-orders.module';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms-and-conditions.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, Router, NavigationEnd } from '@angular/router';
import { AccessGuard } from './core/guards/access.guard';
import { FeatureGuard } from './core/guards/feature.guard';
import { IftaTablePopupComponent } from './modules/filing/components/ifta-table-popup/ifta-table-popup.component';
import {
    PermissionResourcesEnum,
    PermissionsEnum,
} from './shared/model/permissions.model';
import { AuthRedirectComponent } from './pages/auth-redirect/auth-redirect.component';
import { LogoutRedirectComponent } from './pages/logout-redirect/logout-redirect.component';
import { MobileTermsAndConditionsComponent } from './pages/mobile-terms-and-conditions/mobile-terms-and-conditions.component';
import { CarrierAccountActivationComponent } from './pages/carrier-account-activation/carrier-account-activation.component';
import { TokenValidationsComponent } from './pages/token-validations/token-validations.component';
import { DriverAppComponent } from './pages/driver-app/driver-app.component';
import { EmployeeAccountActivationComponent } from './pages/employee-account-activation/employee-account-activation.component';
import { NoPermissionsComponent } from './pages/no-permissions/no-permissions.component';
import { DriverAppVerifyEmailComponent } from './pages/driver-app/driver-app-verify-email/driver-app-verify-email.component';
import { DriverAppInvitationComponent } from './pages/driver-app-invitation/driver-app-invitation.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { WelcomePageComponent } from './pages/welcome-page/welcome-page.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { FreeTrialActivatedComponent } from './pages/free-trial-activated/free-trial-activated.component';
import { PaymentOfferComponent } from './pages/payment-offer/payment-offer.component';
import { DeactivatedAccountSubscriptionComponent } from './pages/deactivated-account-subscription/deactivated-account-subscription.component';
import { LayoutConfigurationDataResolverService } from './shared/services/layout-configuration-data-resolver.service';
import { MaintenanceModeComponent } from './pages/maintenance-mode/maintenance-mode.component';
import { BackgroundScreeningCancellationRequestComponent } from './pages/background-screening-cancellation-request/background-screening-cancellation-request.component';

const routes: Routes = [
    { 
        path: '',
        redirectTo: 'home',
        pathMatch: 'full' },
    {
        path: 'previous-employer-feedback',
        loadChildren: () =>
            import(
                './modules/prev-employer-feedback/prev-employer.module'
            ).then((m) => m.PrevEmployerFeedbackModule),
        data: {
            hideNavbar: false,
            showRootComponents: false,
            requiresLogin: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'welcome-page',
        component: WelcomePageComponent,
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            showRootComponents: false,
            hideNavbar: true,
            requiresPermission: false
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'free-trial-activated',
        component: FreeTrialActivatedComponent,
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            showRootComponents: false,
            hideNavbar: true,
            requiresPermission: false,
            ifAndOnlyIfPaymentIsNotComplete: true
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'payment-offer',
        component: PaymentOfferComponent,
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            showRootComponents: false,
            hideNavbar: false,
            requiresPermission: false,
            ifAndOnlyIfPaymentIsNotComplete: true
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            showRootComponents: false,
            hideNavbar: true,
            requiresPermission: false,
            ifAndOnlyIfPaymentIsNotComplete: true,
            permissionResource: PermissionResourcesEnum.Billing,
            permissionType: PermissionsEnum.Add,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'deactivated-account-subscription',
        component: DeactivatedAccountSubscriptionComponent,
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            showRootComponents: false,
            hideNavbar: false,
            requiresPermission: false,
            ifAndOnlyIfPaymentIsNotComplete: true
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    // {
    //     path: 'home',
    //     component: HomeComponent,
    //     data: { requiresLogin: true },
    //     canActivate: [AccessGuard],
    // },
    {
        path: 'auth-redirect',
        component: AuthRedirectComponent,
        data: { showRootComponents: false, requiresLogin: false },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'logout-redirect',
        component: LogoutRedirectComponent,
        data: { showRootComponents: false, requiresLogin: false },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'activate-account',
        component: CarrierAccountActivationComponent,
        canActivate: [AccessGuard],
        data: {
            onlyAnonymous: true,
            showRootComponents: false,
            hideNavbar: true,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'token-validations/:type',
        component: TokenValidationsComponent,
        // canActivate: [AccessGuard],
        data: {
            showRootComponents: false,
            hideNavbar: true,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'token-validations/:type/:email',
        component: TokenValidationsComponent,
        data: {
            showRootComponents: false,
            hideNavbar: true,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'invitation',
        component: EmployeeAccountActivationComponent,
        data: {
            showRootComponents: false,
            hideNavbar: true,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'reset-password',
        redirectTo: 'auth/reset-password',
        data: {
            // onlyAnonymous: true,
            showRootComponents: false,
            hideNavbar: true,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'auth',
        loadChildren: () =>
            import('./auth/auth.module').then((m) => m.AuthModule),
    },
    {
        path: 'signup',
        loadChildren: () =>
            import('./modules/signup/signup.module').then(
                (m) => m.SignupModule
            ),
    },
    {
        path: 'dashboard',
        redirectTo: 'home'
    },
    {
        path: 'home',
        loadChildren: () =>
            import('./modules/home/home.module').then((m) => m.HomeModule),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            requiresPermission: true,
            isPermissionDeferred: true,
            allowNoCompanySelected: true,
            showRootComponents: true,
            hideNavbar: false,
            showNotification: true,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'eld-monitoring',
        loadChildren: () =>
            import('./modules/eld-monitoring/eld-monitoring.module').then((m) => m.EldMonitoringModule),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            requiresPermission: true,
            isPermissionDeferred: true,
            allowNoCompanySelected: true,
            showRootComponents: true,
            hideNavbar: false,
            showNotification: true,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    // {
    //     path: 'carrier',
    //     loadChildren: () =>
    //         import('./modules/carrier/carrier.module').then(
    //             (m) => m.CarrierModule
    //         ),
    // },
    {
        path: 'carrier-setup',
        loadChildren: () =>
            import('./modules/carrier-setup/carrier-setup.module').then(
                (m) => m.CarrierSetupModule
            ),
        canActivate: [AccessGuard],
        data: {
            hideNavbar: false,
            showRootComponents: false,
            requiresLogin: true,
            showNotification: false,
            ifAndOnlyIfSetupIsNotComplete: true,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'chat-app',
        loadChildren: () =>
            import('./modules/chat-app/chat-app.module').then(
                (m) => m.ChatAppModule
            ),
        canActivate: [AccessGuard],
        data: { requiresLogin: true },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'feature-toggle',
        loadChildren: () =>
            import('./modules/feature-toggle/feature-toggle.module').then(
                (m) => m.FeatureToggleModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            requiresPermission: false,
            ifAndOnlyIfPaymentIsNotComplete: true
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'human-resources',
        redirectTo: 'driver',
    },
    {
        path: 'driver',
        loadChildren: () =>
            import('./modules/driver/driver.module').then(
                (m) => m.DriverModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.Drivers,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'employees',
        loadChildren: () =>
            import('./modules/employees/employees.module').then(
                (m) => m.EmployeesModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.Employees,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        },
        
    },
    {
        path: 'recruitment',
        loadChildren: () =>
            import('./modules/job-openings/job-openings.module').then(
                (m) => m.JobOpeningsModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.JobOpening,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    // {
    //     path: 'application-process',
    //     loadChildren: () =>
    //         import('./modules/application-process/application-process.module').then(
    //             (m) => m.ApplicationProcessModule
    //         ),
    //     data: { requiresLogin: true },
    //     canActivate: [AccessGuard],
    // },
    {
        path: 'demo',
        loadChildren: () =>
            import('./modules/demo/demo.module').then((m) => m.DemoModule),
    },
    {
        path: 'notifications',
        loadChildren: () =>
            import('./modules/alerts/alerts.module').then(
                (m) => m.AlertsModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },

    {
        path: 'settings',
        loadChildren: () =>
            import('./modules/settings/settings.module').then(
                (m) => m.SettingsModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'user-settings',
        loadChildren: () =>
            import('./modules/user-settings/user-settings.module').then(
                (m) => m.UserSettingsModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            showRootComponents: true,
            hideNavbar: false,
            requiresPermission: false,
            allowNoCompanySelected: false //only emnployee can accsess this page
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'assets',
        redirectTo: 'trucks',
    },
    {
        path: 'trucks',
        loadChildren: () =>
            import('./modules/Trucks/trucks.module').then(
                (m) => m.TrucksModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.Trucks,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'trailers',
        loadChildren: () =>
            import('./modules/Trailers/trailers.module').then(
                (m) => m.TrailersModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.Trailers,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'company',
        loadChildren: () =>
            import('./modules/company/company.module').then(
                (m) => m.CompanyModule
            ),
        canActivate: [AccessGuard],
        data: {
            showRootComponents: true,
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.Companies,
            permissionType: PermissionsEnum.View
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },

    {
        path: 'roles',
        loadChildren: () =>
            import('./modules/roles/roles.module').then((m) => m.RolesModule),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.RolesPermissions,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,

            featureName: 'tc-roles-and-permissions',
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },

    {
        path: 'safety',
        redirectTo: 'inspections',
    },

    {
        path: 'inspection',
        loadChildren: () =>
            import('./modules/safety/safety.module').then(
                (m) => m.SafetyModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            featureName: 'tc-safety-inspection',
            permissionResource: PermissionResourcesEnum.Inspections,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },

    {
        path: 'procurement',
        redirectTo: 'procurement',
    },

    {
        path: 'procurement',
        loadChildren: () =>
            import('./modules/procurement/procurement.module').then(
                (m) => m.ProcurementModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.Providers,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,

            featureName: 'tc-procurment-supplier',
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'incidents',
        loadChildren: () =>
            import('./modules/incidents/incidents.module').then(
                (m) => m.IncidentsModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            featureName: 'tc-safety-incident',
            permissionResource: PermissionResourcesEnum.Incidents,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'drug-and-alcohol-tests',
        loadChildren: () =>
            import(
                './modules/drug-and-alcohol-tests/drug-and-alcohol-tests.module'
            ).then((m) => m.DrugAndAlcoholTestsModule),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.DrugandAlcoholTests,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,

            featureName: 'tc-safety-drug-alcohol-test',
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'administration',
        redirectTo: 'company'
    },

    {
        path: 'permits',
        redirectTo: 'permit-setup',
    },

    {
        path: 'permit-setup',
        loadChildren: () =>
            import('./modules/permit-setup/permit-setup.module').then(
                (m) => m.PermitSetupModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            showRootComponents: true,
            requiresLogin: true,
            featureName: 'tc-permits',
            permissionResource: PermissionResourcesEnum.Permits,
            permissionType: PermissionsEnum.View,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },

    {
        path: 'filing',
        loadChildren: () =>
            import('./modules/filing/filing.module').then(
                (m) => m.FilingModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            featureName: 'tc-permits-filing',

            permissionResource: PermissionResourcesEnum.PermitFiling,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },

    {
        path: 'ifta-table/:year/:quarter',
        component: IftaTablePopupComponent,
        data: { showRootComponents: false, hideNavbar: true },
        canActivate: [AccessGuard],
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'insurance-main',
        redirectTo: 'insurance',
    },
    {
        path: 'insurance',
        loadChildren: () =>
            import('./modules/insurance/insurance.module').then(
                (m) => m.InsuranceModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.InsurancePolicies,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,

            featureName: 'tc-insurance',
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'endorsements',
        loadChildren: () =>
            import('./modules/endorsements/endorsements.module').then(
                (m) => m.EndorsementsModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            featureName: 'tc-endorsements',
            permissionResource: PermissionResourcesEnum.Endorsements,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'endorsement-agent',
        loadChildren: () =>
            import('./modules/endorsement-agent/endorsement-agent.module').then(
                (m) => m.EndorsementAgentModule
            ),
        data: {
            hideNavbar: false,
            showRootComponents: false,
            requiresLogin: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'claims',
        loadChildren: () =>
            import('./modules/claims/claims.module').then(
                (m) => m.ClaimsModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            featureName: 'tc-claims',
            permissionResource: PermissionResourcesEnum.Claims,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
        data: { onlyAnonymous: true, showRootComponents: false,hideNavbar: true, },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'mobile-terms-and-conditions',
        component: MobileTermsAndConditionsComponent,
        data: {
            onlyAnonymous: true,
            showRootComponents: false,
            hideNavbar: true,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'insurance-renewals',
        loadChildren: () =>
            import(
                './modules/insurance-renewals/insurance-renewals.module'
            ).then((m) => m.InsuranceRenewalsModule),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.InsuranceRenewals,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,

            featureName: 'tc-insurance-renewals',
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'fuel-card',
        loadChildren: () =>
            import('./modules/fuel-card/fuel-card.module').then(
                (m) => m.FuelCardModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            featureName: 'tc-fuel-card',
            permissionResource: PermissionResourcesEnum.FuelCards,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'pricing-history',
        loadChildren: () =>
            import('./modules/pricing-history/pricing-history.module').then(
                (m) => m.PricingHistoryModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.PricingHistory,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'subscriptions',
        loadChildren: () =>
            import('./modules/subscriptions/subscriptions.module').then(
                (m) => m.SubscriptionsModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.Subscriptions,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'roles-and-permissions-system',
        loadChildren: () =>
            import(
                './modules/roles-and-permissions-system/roles-and-permissions-system.module'
            ).then((m) => m.RolesAndPermissionsSystemModule),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            featureName: 'tc-roles-and-permissions-system',
            permissionResource: PermissionResourcesEnum.SystemRolesPermissions,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'sales-sys',
        redirectTo: 'account-requests',
    },
    {
        path: 'fuel-cards',
        redirectTo: 'fuel-card',
    },
    {
        path: 'account-requests',
        loadChildren: () =>
            import('./modules/account-requests/account-requests.module').then(
                (m) => m.AccountRequestsModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.AccountRequests,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'administration-sys',
        redirectTo: 'roles-and-permissions-system',
        data: {
            requiresLogin: true,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    // driver-app/verification?d=f47VPYxAcZFYw2S7fMHelxtlM0AstOOQKzw6jYpvNGXBDa60LLdfW_hcV3wbIsS5qqRvyfkGJAX_rfWM7-y8Nw
    {
        path: 'driver-app/verification',
        component: DriverAppVerifyEmailComponent,
        data: {
            hideNavbar: true,
            showRootComponents: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'driver-app/invitation',
        component: DriverAppInvitationComponent,
        data: {
            hideNavbar: true,
            showRootComponents: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'driver-app',
        component: DriverAppComponent,
        data: {
            hideNavbar: true,
            showRootComponents: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        },
        children: [
            { path: '**', component: DriverAppComponent }
        ]
    },
    {
        path: 'no-permissions',
        component: NoPermissionsComponent,
        // canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            hideNavbar: false,
            showRootComponents: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'blogs',
        loadChildren: () =>
            import(
                './modules/sys-blogs/sys-blogs.module'
            ).then((m) => m.SysBlogsModule),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            featureName: 'tc-sys-blogs',
            // permissionResource: PermissionResourcesEnum.SystemRolesPermissions,
            // permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'operations',
        redirectTo: 'pairing',
    },
    {
        path: 'pairing',
        loadChildren: () =>
            import(
                './modules/pairing/pairing.module'
            ).then((m) => m.PairingModule),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            featureName: 'tc-pairing',
            permissionResource: PermissionResourcesEnum.Pairings,
            permissionType: PermissionsEnum.View,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'repairs-and-maintenances',
        redirectTo: 'repairs',
    },
    {
        path: 'repairs',
        loadChildren: () =>
            import('./modules/repairs/repairs.module').then(
                (m) => m.RepairsModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.Repairs,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'maintenance-schedules',
        loadChildren: () =>
            import('./modules/maintenance-schedules/maintenance-schedules.module').then(
                (m) => m.MaintenanceSchedulesModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.MaintenanceSchedules,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'maintenance-mode',
        component: MaintenanceModeComponent,
        data: {
            requiresLogin: false,
            hideNavbar: true,
            showRootComponents: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'background-screening-cancellation-request',
        component: BackgroundScreeningCancellationRequestComponent,
        data: { 
            showRootComponents: false,
            hideNavbar: true,
            requiresLogin: false
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'background-screening',
        redirectTo: 'background-screening-orders',
    },
    {
        path: 'background-screening-orders',
        loadChildren: () =>
            import('./modules/background-screening-orders/background-screening-orders.module').then(
                (m) => m.BackgroundScreeningOrdersModule
            ),
        canActivate: [AccessGuard],
        data: {
            requiresLogin: true,
            permissionResource: PermissionResourcesEnum.BackgroundScreeningOrders,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    {
        path: 'inspections',
        loadChildren: () =>
            import('./modules/inspections/inspections.module').then(
                (m) => m.InspectionsModule
            ),
        canActivate: [AccessGuard, FeatureGuard],
        data: {
            requiresLogin: true,
            featureName: 'tc-safety-inspection',
            permissionResource: PermissionResourcesEnum.Inspections,
            permissionType: PermissionsEnum.List,
            showRootComponents: true,
            hideNavbar: false,
        },
        resolve: {
            layoutConfigurationData: LayoutConfigurationDataResolverService
        }
    },
    { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(router: Router, gaService: GoogleAnalyticsService) {
        router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // 4. Send pageview event to Google Analytics
                gaService.pageView('page', event.urlAfterRedirects);
            }
        });
    }
}
