import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { PhoneNumberMaskDirective } from './directives/phone-number-mask.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectCompanyComponent } from './components/dialogs/select-company/select-company.component';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { SafePipe } from './pipes/safe.pipe';
import { SsnMaskDirective } from './directives/ssn-mask.directive';
import { TagInputComponent } from './components/tag-input/tag-input.component';
import { SaveChangesComponent } from './components/dialogs/save-changes/save-changes.component';
import { ConfirmComponent } from './components/dialogs/confirm/confirm.component';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { AppTableComponent } from './components/app-table/app-table.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { NoProviderModalComponent } from './no-provider-modal/no-provider-modal.component';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { RequiredBoxComponent } from './components/required-box/required-box.component';
import { AutocompleteDropdownComponent } from './components/autocomplete-dropdown/autocomplete-dropdown.component';
import { AppCardComponent } from './components/app-card/app-card.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { MyFilterPipe } from './pipes/myfilter.pipe';
import { AppTableEditableComponent } from './components/app-table-editable/app-table-editable.component';
import { ImportListComponent } from './components/import-list/import-list.component';
import { ImportExportAlertComponent } from './components/import-export-alert/import-export-alert.component';
import { MyDurationPickerDirective } from './directives/my-duration-picker.directive';
import { PhoneNumberInputComponent } from './components/phone-number-input/phone-number-input.component';
import { FilesListComponent } from './components/files-list/files-list.component';
import { TimeInputDirective } from './directives/time-mask.directive';
import { AddTruckComponent } from './components/dialogs/add-truck/add-truck.component';
import { AddTrailerComponent } from './components/dialogs/add-trailer/add-trailer.component';
import { AddDriverComponent } from './components/dialogs/add-driver/add-driver.component';
import { MatMenuModule } from '@angular/material/menu';
import { TrailerQuickAddComponent } from './components/dialogs/trailer-quick-add/trailer-quick-add.component';
import { TruckQuickAddComponent } from './components/dialogs/truck-quick-add/truck-quick-add.component';
import { DriverQuickAddComponent } from './components/dialogs/driver-quick-add/driver-quick-add.component';
import { DocumentsSelectModalComponent } from './components/dialogs/documents-select-modal/documents-select-modal.component';
import { InputUnitComponent } from './components/input-unit/input-unit.component';
import { ColResizeDirective } from './directives/col-resize.directive';
import { ColResizeComponent } from './directives/col-resize/col-resize.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { AutocompleteAddressComponent } from './components/autocomplete-address/autocomplete-address.component';
import { CalendarModule } from 'primeng/calendar';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { NotificationPermissionComponent } from './components/dialogs/notification-permission/notification-permission.component';
import { SignatureDetailsComponent } from './components/signature-details/signature-details.component';
import { DateMaskDirective } from './directives/date-mask.directive';
import { AppTableErrorsComponent } from './components/app-table-errors/app-table-errors.component';
import { TrimZerosPipe } from './pipes/trim-zeros.pipe';
import { FirstCharPipe } from './pipes/first-char.pipe';
import { MatDialogModule } from '@angular/material/dialog';
import { SpaceFirstLetterDirective } from './directives/space-first-letter.directive';
import { DownloadReportComponent } from './components/dialogs/download-report/download-report.component';
import { DownloadListReportComponent } from './components/dialogs/download-list-report/download-list-report.component';
import { DownloadReportAdvanceComponent } from './components/dialogs/download-report-advance/download-report-advance.component';
import { ExportListComponent as ExportListComponent2 } from './components/dialogs/export-list/export-list.component';
import { AutocompleteDropdownMultiSelectComponent } from './components/autocomplete-dropdown-multi-select/autocomplete-dropdown-multi-select.component';
import { NgbPopoverModule, NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { PasswordComponent } from './components/password/password.component';
import { CarrierAccountActivationComponent } from '../pages/carrier-account-activation/carrier-account-activation.component';
import { DriverAppInvitationComponent } from '../pages/driver-app-invitation/driver-app-invitation.component';
import { EmployeeAccountActivationComponent } from '../pages/employee-account-activation/employee-account-activation.component';
import { DragDropFileDirective } from './directives/drag-drop-file.directive';
import { EMailChangingComponent } from './components/dialogs/e-mail-changing/e-mail-changing.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { TcDropdownComponent } from './components/tc-dropdown/tc-dropdown.component';
import { DropdownModule } from 'primeng/dropdown';
import { WelcomeToTruckinCentralComponent } from './components/dialogs/welcome-to-truckin-central/welcome-to-truckin-central.component';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { TcAutocompleteDropdownComponent } from './components/tc-autocomplete-dropdown/tc-autocomplete-dropdown.component';
import { MultiSelectModule } from 'primeng/multiselect';
import { TcDropdownMultiSelectComponent } from './components/tc-dropdown-multi-select/tc-dropdown-multi-select.component';
import { SnoozeReminderComponent } from './components/dialogs/snooze-reminder/snooze-reminder.component';
import { TableModule } from 'primeng/table';
import { TcTableComponent } from './components/tc-table/tc-table.component';
import { TcCheckboxComponent } from './components/tc-checkbox/tc-checkbox.component';
import { BrowserModule } from '@angular/platform-browser';
import { NumberWithCommas } from './pipes/number-with-commas';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FileUploadBoxComponent } from './components/file-upload-box/file-upload-box.component';
import { SaveChangesV2Component } from './components/dialogs/save-changes-v2/save-changes-v2.component';

@NgModule({
    declarations: [
        SelectCompanyComponent,
        OnlyNumberDirective,
        SafePipe,
        NumberWithCommas,
        SsnMaskDirective,
        TagInputComponent,
        PhoneNumberMaskDirective,
        SaveChangesComponent,
        ConfirmComponent,
        DropdownComponent,
        AppTableComponent,
        NoProviderModalComponent,
        FileUploadComponent,
        RequiredBoxComponent,
        AutocompleteDropdownComponent,
        AppCardComponent,
        ImageViewerComponent,
        MyFilterPipe,
        AppTableEditableComponent,
        ImportListComponent,
        ImportExportAlertComponent,
        MyDurationPickerDirective,
        PhoneNumberInputComponent,
        FilesListComponent,
        TimeInputDirective,
        AddTruckComponent,
        AddTrailerComponent,
        AddDriverComponent,
        DriverQuickAddComponent,
        TruckQuickAddComponent,
        TrailerQuickAddComponent,
        DocumentsSelectModalComponent,
        InputUnitComponent,
        ColResizeDirective,
        ColResizeComponent,
        BreadcrumbsComponent,
        AutocompleteAddressComponent,
        DatePickerComponent,
        NotificationPermissionComponent,
        SignatureDetailsComponent,
        DateMaskDirective,
        AppTableErrorsComponent,
        TrimZerosPipe,
        FirstCharPipe,
        SpaceFirstLetterDirective,
        DownloadReportComponent,
        DownloadListReportComponent,
        DownloadReportAdvanceComponent,
        ExportListComponent2,
        AutocompleteDropdownMultiSelectComponent,
        PasswordComponent,
        CarrierAccountActivationComponent,
        DriverAppInvitationComponent,
        EmployeeAccountActivationComponent,
        DragDropFileDirective,
        EMailChangingComponent,
        TcDropdownComponent,
        WelcomeToTruckinCentralComponent,
        TcAutocompleteDropdownComponent,
        TcDropdownMultiSelectComponent,
        SnoozeReminderComponent,
        TcTableComponent,
        TcCheckboxComponent,
        FileUploadBoxComponent,
        SaveChangesV2Component
    ],
    imports: [
        FormsModule,
        RouterModule,
        ReactiveFormsModule,
        CommonModule,
        NgxPaginationModule,
        MatDialogModule,
        MatCheckboxModule,
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        CalendarModule,
        NgbTooltip,
        NgbPopoverModule,
        MatMenuModule,
        GoogleMapsModule,
        DropdownModule,
        AutoCompleteModule,
        MultiSelectModule,
        TableModule,
        MatProgressSpinnerModule
    ],
    exports: [
        TranslateModule,
        MatDialogModule,
        SelectCompanyComponent,
        OnlyNumberDirective,
        SafePipe,
        NumberWithCommas,
        SsnMaskDirective,
        TagInputComponent,
        PhoneNumberMaskDirective,
        DropdownComponent,
        AppTableComponent,
        FileUploadComponent,
        RequiredBoxComponent,
        AutocompleteDropdownComponent,
        AppCardComponent,
        ImageViewerComponent,
        MyFilterPipe,
        AppTableEditableComponent,
        MyDurationPickerDirective,
        FormsModule,
        ReactiveFormsModule,
        PhoneNumberInputComponent,
        FilesListComponent,
        TimeInputDirective,
        InputUnitComponent,
        ColResizeDirective,
        ColResizeComponent,
        BreadcrumbsComponent,
        AutocompleteAddressComponent,
        DatePickerComponent,
        AppTableErrorsComponent,
        TrimZerosPipe,
        FirstCharPipe,
        SpaceFirstLetterDirective,
        AutocompleteDropdownMultiSelectComponent,
        NgbPopoverModule,
        PasswordComponent,
        DragDropFileDirective,
        EMailChangingComponent,
        MatMenuModule,
        GoogleMapsModule,
        TcDropdownComponent,
        TcAutocompleteDropdownComponent,
        TcDropdownMultiSelectComponent,
        TcTableComponent,
        TcCheckboxComponent,
        FileUploadBoxComponent,
        SaveChangesV2Component
    ],
})
export class SharedModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
