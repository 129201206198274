import { WelcomeToTruckinCentralComponent } from './../../dialogs/welcome-to-truckin-central/welcome-to-truckin-central.component';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subscription, BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { Router } from '@angular/router';
import { CompanyService } from './../../../services/company.service';
import { ContextService } from './../../../services/context.service';
import { Component, OnInit, OnDestroy, HostListener, AfterViewInit, ViewChild } from '@angular/core';
import { SafeHtml, SafeUrl } from '@angular/platform-browser';
import { EmployeeCompany } from 'src/app/core/models/company.model';
import { FeatureService } from 'src/app/shared/services/feature.service';
import { FileService } from 'src/app/shared/services/file.service';
import { PermissionsService } from 'src/app/shared/services/permissions.service';
import { PermissionResourcesEnum } from 'src/app/shared/model/permissions.model';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';

const APP_MENU_OPEND_KEY = 'menu-opend';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, OnDestroy {
    @ViewChild('popover1Trigger', { static: false }) popover1Trigger: NgbPopover | null = null;
    @ViewChild('popover2Trigger', { static: false }) popover2Trigger: NgbPopover | null = null;

    opened: boolean =
        window.localStorage.getItem(APP_MENU_OPEND_KEY) == 'true'
            ? true
            : false;
    showCompanyMenu: boolean = true;
    userCompanies: EmployeeCompany[] = [];
    userIdSub: Subscription | null = null;
    permissionsSub: Subscription | null = null;
    screenWidthSub: Subscription | null = null;
    companyNameSub: Subscription | null = null;
    showSettings: boolean = true;
    userId: number = 0;
    userName: string = '';
    companyLocationName: string = 'Loading ...';
    companyLogoLink: string = '';
    companyLogoInitials: string = '';

    sideBarList: any[] = [];
    event$: any;
    /***** */
    screenWidth: number = 0;
    hasCompanyNotificationUnread: boolean = false;
    private screenWidth$ = new BehaviorSubject<number>(window.innerWidth);
    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.screenWidth$.next(event.target.innerWidth);
    }
    /***** */
    driverIconSvg: SafeHtml | null = null;
    dashbordIconSvg: SafeHtml | null = null;
    jobIconSvg: SafeHtml | null = null;
    jobOpeningIconSvg: SafeHtml | null = null;
    driverIconSvg1: SafeHtml | null = null;
    companyId: number = this.contextService.getCompanyId();
    isBusy: number = 0;
    // companyLocationId: number = this.contextService.getCompanyLocationId();
    imageSrc: Observable<SafeUrl> | null = null;
    constructor(
        public contextService: ContextService,
        public router: Router,
        public companyService: CompanyService,
        public shared: SharedService,
        public fileService: FileService,
        public notificationsService: NotificationsService,
        private featureService: FeatureService,
        private permissionsService: PermissionsService,
        private dialog: MatDialog
    ) {
        // this.event$ = this.router.events.subscribe((event: NavigationEvent) => {
        //     if (event instanceof NavigationStart) {
        //         this.sideBarList.forEach((e: any) => {
        //             if (e.subMenu) {
        //                 e.subMenu.forEach((sub: any) => {
        //                     if (event.url == sub.routerLink)
        //                         e.openSubMenu = true;
        //                 });
        //             }
        //         });
        //     }
        // });
    }

    async ngOnInit() {
        this.isBusy++;
        await this.contextService.getInitPromise();
        this.screenWidthSub = this.screenWidth$.subscribe((width) => {
            this.screenWidth = width;
            if (this.screenWidth <= 992) {
                this.opened = false;
            } else {
                this.opened = true;
            }
            this.shared.menuOpend$.next(this.opened);
            this.sideNavCollapse();
        });
        this.sideNavCollapse();
        this.userIdSub = this.contextService.loggedInUserId$.subscribe(
            (userId) => {
                this.userId = userId;
            }
        );
        this.companyNameSub = this.contextService.companyName$.subscribe(
            (compLocName) => {
                this.companyLocationName = compLocName;
                this.companyLogoLink = this.companyService.getCompanyLogoLink(
                    this.contextService.getCompanyId(),
                    this.contextService.getToken()
                );
                this.companyLogoInitials =
                    this.contextService.getCompanyInitials();
                if (this.isBusy > 0) {
                    // waiting 500 to load the company logo
                    setTimeout(() => {
                        this.isBusy--;
                    }, 500);
                }
            }
        );
        // this.showCompanyMenu = this.contextService.isMoreThanOneCompany();
        this.userCompanies = this.contextService.employeeCompanies$.getValue();
        // await this.featureService.getAllFeatures();
        this.assignSidebarItems();
        this.permissionsSub =
            this.permissionsService.PermissionsList$.subscribe((el) => {
                this.assignSidebarItems();
            });
        this.imageSrc = this.fileService.fetchImageWithToken(this.contextService.companyLogoId$.getValue());
        this.contextService.isShowPopoverHelp$.subscribe(val => {
            if (val && this.userCompanies.length > 1) {
                if (this.userCompanies.length > 1) {
                    setTimeout(() => {
                        this.popover1Trigger?.open();
                    });
                    this.contextService.isShowPopoverHelp$.next(false);
                }
            }
        })

    }

    // async welcomeToTruckinCentral() {
    //     let dialog = this.dialog.open(WelcomeToTruckinCentralComponent, {
    //         disableClose: true,
    //         autoFocus: false,
    //         width: '57.6rem',
    //         data: {},
    //     });
    //     let resultContract = await lastValueFrom(dialog.afterClosed());
    //     if (resultContract == 'start') {
    //         this.popover1Trigger?.open();
    //     } else {
    //         window.localStorage.removeItem("isPasswordSet");
    //     }
    // }

    next() {
        this.popover1Trigger?.close();
        let elm = document.getElementById("select-company-button");
        if (elm) {
            elm.click();
            setTimeout(() => {
                this.popover2Trigger?.open();
            }, 100);
        }
    }

    finish() {
        this.popover2Trigger?.close();
    }

    setSidebarActive(...args: string[]) {
        args = args.map((i) => '/' + i);
        let url: string = location.pathname;
        return args.some((term) => url.includes(term));
    }

    async setNumberOfNotifications() {
        let userId = this.contextService.getUserId();
        // this.userCompany = await this.notificationsService.setOtherLocationNotificationsCount(this.userCompany, userId);
    }

    getParentRouterLink(item: any) {
        let link: string = '/home';
        if (item.subMenu) {
            let notAssignLink: boolean = true;
            item.subMenu.forEach((e: any) => {
                if (e.show && notAssignLink) {
                    link = e.routerLink;
                    notAssignLink = false;
                }
            });
        } else {
            link = item.routerLink
        }
        return link;
    }

    collapseAll() {
        this.opened = !this.opened;
        window.localStorage.setItem(
            APP_MENU_OPEND_KEY,
            this.opened == true ? 'true' : 'false'
        );
        this.shared.menuOpend$.next(this.opened);
        this.sideNavCollapse();
    }

    sideNavCollapse() {
        const sideNav = document.getElementById('side-nav');
        if (sideNav != null) {
            if (this.opened) {
                sideNav.classList.add('side-nav-opend');
                sideNav.classList.remove('side-nav-closed');
            } else {
                sideNav.classList.remove('side-nav-opend');
                sideNav.classList.add('side-nav-closed');
            }
        }
    }

    openSubMenu(item: any) {
        this.sideBarList.forEach((e) => {
            if (e.subMenu && e.id != item.id) e.openSubMenu = false;
        });
        if (item.subMenu) {
            item.openSubMenu = !item.openSubMenu;
        }
    }

    closeAllSubMenu() {
        this.sideBarList.forEach((e) => {
            e.openSubMenu = false;
        });
    }

    onSettingsClick() {
        if (
            this.permissionsService.isEditGranted(
                PermissionResourcesEnum.Integrations
            )
        ) {
            return 'settings';
        }
        if (
            this.permissionsService.isEditGranted(
                PermissionResourcesEnum.Notifications
            )
        ) {
            return 'settings/notifications';
        }
        if (
            this.permissionsService.isEditGranted(
                PermissionResourcesEnum.OutgoingEmail
            )
        ) {
            return 'settings/outgoing-email';
        }
        if (
            this.permissionsService.isEditGranted(
                PermissionResourcesEnum.DrugandAlcoholTests
            )
        ) {
            return 'settings/drug-alcohol-testing';
        }
        return 'settings';
    }

    // selectCompanyLocation(companyLocationId: number) {
    //     this.contextService.selectCompany(companyLocationId);
    //     this.sideBarList.forEach((e) => {
    //         if (e.subMenu) e.openSubMenu = false;
    //     });
    //     this.router.navigate(['home']);
    // }

    async selectCompany(company: EmployeeCompany) {
        this.isBusy++;
        this.contextService.selectCompany(company.id);
        this.sideBarList.forEach((e) => {
            if (e.subMenu) e.openSubMenu = false;
        });
        this.contextService.companyName$.next(company.name);
        this.contextService.companyLogoId$.next(company.logoDocumentId);
        this.imageSrc = this.fileService.fetchImageWithToken(this.contextService.companyLogoId$.getValue());
        await this.permissionsService.getAllUserPermissions();
        this.closeAllSubMenu();
        const PERMISSIONS_KEY = 'user-permissions';
        const permissionList = localStorage.getItem(PERMISSIONS_KEY)
            ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY) ?? '')
            : null;
        if (permissionList == null || (permissionList && !permissionList.userResourcesPermissions.some((up: any) => up.permissions.length > 0))) {
            this.router.navigate(['/no-permissions']);
        } else {
            this.router.navigate(['home']);
        }
    }

    public assignSidebarItems() {
        if (this.contextService.isSysAdmin()) {
            this.sideBarList = [
                {
                    title: 'DASHBOARD',
                    id: 'dashboard',
                    routerLink: '/home',
                    icon: 'dashboard.svg',
                    show: true,
                },
                {
                    title: 'SALES',
                    id: 'sales-sys',
                    openSubMenu: this.setSidebarActive(
                        'sales-sys',
                        'account-requests',
                    ),
                    routerLink: '/sales-sys',
                    icon: 'sales.svg',
                    show: this.permissionsService.isListGranted(
                        PermissionResourcesEnum.AccountRequests
                    ),
                    subMenu: [
                        {
                            title: 'ACCOUNT_REQUESTS',
                            id: 'account-requests',
                            routerLink: '/account-requests',
                            icon: 'account-requests-icon.svg',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.AccountRequests
                            ) || true,
                        },
                    ],
                },
                {
                    title: 'FUEL_CARDS',
                    id: 'fuel-cards',
                    openSubMenu: this.setSidebarActive(
                        'fuel-cards',
                        'fuel-card',
                        'pricing-history',
                        'subscriptions'
                    ),
                    routerLink: '/fuel-cards',
                    icon: 'fuel-cards.svg',
                    show: this.permissionsService.isListGranted(
                        PermissionResourcesEnum.FuelCards
                    ) || this.permissionsService.isListGranted(
                        PermissionResourcesEnum.PricingHistory
                    ) || this.permissionsService.isListGranted(
                        PermissionResourcesEnum.Subscriptions
                    ),
                    subMenu: [
                        {
                            title: 'FUEL_CARD_PROMOTION',
                            id: 'fuel-card',
                            routerLink: '/fuel-card',
                            icon: 'fuel-card-promotion.svg',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.FuelCards
                            ),
                        },
                        {
                            title: 'PRICING_HISTORY',
                            id: 'pricing-history',
                            routerLink: '/pricing-history',
                            icon: 'history.svg',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.PricingHistory
                            ),
                        },
                        {
                            title: 'SUBSCRIPTIONS',
                            id: 'subscriptions',
                            routerLink: '/subscriptions',
                            icon: 'credit-card.svg',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.Subscriptions
                            ),
                        },
                    ],
                },
                {
                    title: 'ADMINISTRATION',
                    id: 'administration-sys',
                    openSubMenu: this.setSidebarActive(
                        'administration-sys',
                        'roles-and-permissions-system',
                        'blogs'
                    ),
                    routerLink: '/administration-sys',
                    icon: 'administration.svg',
                    show: this.permissionsService.isListGranted(
                        PermissionResourcesEnum.SystemRolesPermissions
                    ) || this.permissionsService.isListGranted(
                        PermissionResourcesEnum.Blogs
                    ),
                    subMenu: [
                        {
                            title: 'ROLES_&_PERMISSIONS',
                            id: 'roles-and-permissions-system',
                            routerLink: '/roles-and-permissions-system',
                            icon: 'roles-key-grey.svg',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.SystemRolesPermissions
                            ),
                        },
                        {
                            title: 'BLOGS',
                            id: 'blogs',
                            routerLink: '/blogs',
                            icon: 'blogicon.svg',
                            show:
                                this.featureService.checkAccess(
                                    'tc-sys-blogs'
                                ) &&
                                this.permissionsService.isListGranted(
                                    PermissionResourcesEnum.Blogs
                                ),
                        },
                    ],
                },
            ];
        } else {
            const PERMISSIONS_KEY = 'user-permissions';
            const permissionList = localStorage.getItem(PERMISSIONS_KEY)
                ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY) ?? '')
                : null;
            this.sideBarList = [
                {
                    title: 'DASHBOARD',
                    id: 'dashboard',
                    routerLink: '/dashboard',
                    icon: 'dashboard.svg',
                    show: permissionList.userResourcesPermissions.length > 0,
                    openSubMenu: this.setSidebarActive('home', 'eld-monitoring'),
                    subMenu: [
                        {
                            title: 'OVERVIEW',
                            id: 'overview',
                            routerLink: '/home',
                            show: true,
                        },
                        {
                            title: 'ELD_MONITORING',
                            id: 'eld-monitoring',
                            routerLink: '/eld-monitoring',
                            icon: 'eld-monitoring-icon.svg',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.EldMonitoring
                            ),
                        },
                    ]
                },
                {
                    title: 'HUMAN_RESOURCES',
                    id: 'employees',
                    routerLink: '/human-resources',
                    icon: 'human-resources.svg',
                    openSubMenu: this.setSidebarActive('driver', 'employees'),
                    show:
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.Drivers
                        ) ||
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.Employees
                        ),
                    subMenu: [
                        {
                            title: 'DRIVERS',
                            id: 'drivers',
                            routerLink: '/driver',
                            icon: 'drivers-icon.svg',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.Drivers
                            ),
                        },
                        {
                            title: 'EMPLOYEES',
                            id: 'employees',
                            routerLink: '/employees',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.Employees
                            ),
                        },
                    ],
                },
                {
                    title: 'RECRUITING',
                    id: 'recruitment',
                    routerLink: '/recruitment',
                    icon: 'recruiting.svg',
                    openSubMenu: this.setSidebarActive(
                        'recruitment',
                        'job-openings',
                        'application-process'
                    ),
                    show: this.permissionsService.isListGranted(
                        PermissionResourcesEnum.JobOpening
                    ),
                    subMenu: [
                        {
                            title: 'JOB_OPENINGS',
                            id: 'job-openings',
                            routerLink: '/recruitment/job-openings',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.JobOpening
                            ),
                        },
                    ],
                },
                {
                    title: 'ASSETS',
                    id: 'Assets',
                    openSubMenu: this.setSidebarActive('trucks', 'trailers'),
                    routerLink: '/assets',
                    icon: 'assets.svg',
                    show:
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.Trailers
                        ) ||
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.Trucks
                        ),
                    subMenu: [
                        {
                            title: 'TRUCKS',
                            id: 'trucks',
                            routerLink: '/trucks',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.Trucks
                            ),
                        },
                        {
                            title: 'TRAILERS',
                            id: 'trailers',
                            routerLink: '/trailers',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.Trailers
                            ),
                        },
                    ],
                },
                {
                    title: 'SUPPORT_SERVICES',
                    id: 'procurement',
                    openSubMenu: this.setSidebarActive('suppliers'),
                    routerLink: '/procurement',
                    icon: 'support-services.svg',
                    show:
                        this.featureService.checkAccess(
                            'tc-procurment-supplier'
                        ) &&
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.Providers
                        ),
                    subMenu: [
                        {
                            title: 'PROVIDERS',
                            id: 'vendors',
                            routerLink: '/procurement/suppliers',
                            show:
                                this.featureService.checkAccess(
                                    'tc-procurment-supplier'
                                ) &&
                                this.permissionsService.isListGranted(
                                    PermissionResourcesEnum.Providers
                                ),
                        },
                    ],
                },
                {
                    title: 'SAFETY',
                    id: 'Safety',
                    openSubMenu: this.setSidebarActive(
                        'inspection',
                        'inspections',
                        'incidents',
                        'drug-and-alcohol-tests'
                    ),
                    routerLink: '/safety',
                    icon: 'safety.svg',
                    show:
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.Inspections
                        ) ||
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.Incidents
                        ) ||
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.DrugandAlcoholTests
                        ),
                    subMenu: [
                        // {
                        //     title: 'INSPECTION',
                        //     id: 'inspection',
                        //     routerLink: '/inspection',
                        //     show:
                        //         this.featureService.checkAccess(
                        //             'tc-safety-inspection'
                        //         ) &&
                        //         this.permissionsService.isListGranted(
                        //             PermissionResourcesEnum.Inspections
                        //         ),
                        // },
                        {
                            title: 'INSPECTIONS',
                            id: 'inspections',
                            routerLink: '/inspections',
                            show:
                                this.featureService.checkAccess(
                                    'tc-safety-inspection-new'
                                ) &&
                                this.permissionsService.isListGranted(
                                    PermissionResourcesEnum.Inspections
                                ),
                        },
                        {
                            title: 'INCIDENTS',
                            id: 'incidents',
                            routerLink: '/incidents',
                            show:
                                this.featureService.checkAccess(
                                    'tc-safety-incident'
                                ) &&
                                this.permissionsService.isListGranted(
                                    PermissionResourcesEnum.Incidents
                                ),
                        },
                        {
                            title: 'DRUG_AND_ALCOHOL_TESTS',
                            id: 'drug-and-alcohol-tests',
                            routerLink: '/drug-and-alcohol-tests',
                            show:
                                this.featureService.checkAccess(
                                    'tc-safety-drug-alcohol-test'
                                ) &&
                                this.permissionsService.isListGranted(
                                    PermissionResourcesEnum.DrugandAlcoholTests
                                ),
                        },
                    ],
                },
                {
                    title: 'REPAIRS_AND_MAINTENANCES',
                    id: 'repairs-and-maintenances',
                    openSubMenu: this.setSidebarActive('repairs', 'maintenance-schedules'),
                    routerLink: '/repairs-and-maintenances',
                    icon: 'repairs-and-maintenances.svg',
                    show:
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.Repairs
                        )
                        ||
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.MaintenanceSchedules
                        )
                    ,
                    subMenu: [
                        {
                            title: 'REPAIRS',
                            id: 'repairs',
                            routerLink: '/repairs',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.Repairs
                            ),
                        },
                        {
                            title: 'MAINTENANCE_SCHEDULES',
                            id: 'maintenance-schedules',
                            routerLink: '/maintenance-schedules',
                            show: this.permissionsService.isListGranted(
                                PermissionResourcesEnum.MaintenanceSchedules
                            ),
                        },
                    ],
                },
                {
                    title: 'PREMITS',
                    id: 'permits',
                    openSubMenu: this.setSidebarActive(
                        'permit-setup',
                        'filing'
                    ),
                    routerLink: '/permits',
                    icon: 'permits.svg',
                    show:
                        this.featureService.checkAccess('tc-permits')
                        && (this.permissionsService.isViewGranted(PermissionResourcesEnum.Permits)
                            || this.permissionsService.isListGranted(PermissionResourcesEnum.Permits)
                            || this.permissionsService.isViewGranted(PermissionResourcesEnum.PermitFiling)
                            || this.permissionsService.isListGranted(PermissionResourcesEnum.PermitFiling)
                        ),
                    subMenu: [
                        {
                            title: 'PREMIT_SETUP',
                            id: 'permit-setup',
                            routerLink: 'permit-setup',
                            show:
                                this.featureService.checkAccess('tc-permits') &&
                                this.permissionsService.isViewGranted(PermissionResourcesEnum.Permits),
                        },
                        {
                            title: 'FILING',
                            id: 'filing',
                            routerLink: '/filing',
                            show:
                                this.featureService.checkAccess(
                                    'tc-permits-filing'
                                ) &&
                                this.permissionsService.isListGranted(PermissionResourcesEnum.PermitFiling),
                        },
                    ],
                },
                {
                    title: 'INSURANCE',
                    id: 'insurance',
                    routerLink: '/insurance-main',
                    icon: 'insurance.svg',
                    openSubMenu: this.setSidebarActive(
                        'insurance-policy',
                        'insurance-renewals',
                        'endorsements',
                        'claims'
                    ),
                    show:
                        (this.featureService.checkAccess('tc-insurance') &&
                            this.permissionsService.isListGranted(
                                PermissionResourcesEnum.InsurancePolicies
                            )) ||
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.Endorsements
                        ) ||
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.InsuranceRenewals
                        ) ||
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.Claims
                        ),
                    subMenu: [
                        {
                            title: 'INSURANCE_POLICIES',
                            id: 'insurance-policy',
                            routerLink: '/insurance',
                            show:
                                this.featureService.checkAccess(
                                    'tc-insurance'
                                ) &&
                                this.permissionsService.isListGranted(
                                    PermissionResourcesEnum.InsurancePolicies
                                ),
                        },
                        {
                            title: 'INSURANCE_RENEWALS',
                            id: 'insurance-renewals',
                            routerLink: '/insurance-renewals',
                            show:
                                this.featureService.checkAccess(
                                    'tc-insurance-renewals'
                                ) &&
                                this.permissionsService.isListGranted(
                                    PermissionResourcesEnum.InsuranceRenewals
                                ),
                        },
                        {
                            title: 'ENDORSEMENTS',
                            id: 'endorsements',
                            routerLink: '/endorsements',
                            show:
                                this.featureService.checkAccess(
                                    'tc-endorsements'
                                ) &&
                                this.permissionsService.isListGranted(
                                    PermissionResourcesEnum.Endorsements
                                ),
                        },
                        {
                            title: 'CLAIMS',
                            id: 'claims',
                            routerLink: '/claims',
                            show:
                                this.featureService.checkAccess('tc-claims') &&
                                this.permissionsService.isListGranted(
                                    PermissionResourcesEnum.Claims
                                ),
                        },
                    ],
                },
                {
                    title: 'OPERATIONS',
                    id: 'operations',
                    routerLink: '/operations',
                    icon: 'operations.svg',
                    openSubMenu: this.setSidebarActive(
                        'pairing',
                    ),
                    show: this.featureService.checkAccess('tc-pairing') && this.permissionsService.isViewGranted(
                        PermissionResourcesEnum.Pairings
                    ),
                    subMenu: [
                        {
                            title: 'PAIRING',
                            id: 'pairing',
                            routerLink: '/pairing',
                            show:
                                this.featureService.checkAccess(
                                    'tc-pairing'
                                ) &&
                                this.permissionsService.isViewGranted(
                                    PermissionResourcesEnum.Pairings
                                ),
                        }
                    ]
                },
                {
                    title: 'ADMINISTRATION',
                    id: 'administration',
                    openSubMenu: this.setSidebarActive(
                        'administration',
                        'company',
                        'roles',
                        'roles-and-permissions-system'
                    ),
                    routerLink: '/administration',
                    icon: 'administration.svg',
                    show:
                        (this.featureService.checkAccess(
                            'tc-company-information'
                        ) &&
                            this.permissionsService.isViewGranted(
                                PermissionResourcesEnum.Companies
                            )) ||
                        this.permissionsService.isListGranted(
                            PermissionResourcesEnum.RolesPermissions
                        ),
                    subMenu: [
                        {
                            title: 'COMPANY_INFORMATION',
                            id: 'company-information',
                            routerLink: '/company',
                            show:
                                this.featureService.checkAccess(
                                    'tc-company-information'
                                ) &&
                                this.permissionsService.isViewGranted(
                                    PermissionResourcesEnum.Companies
                                ),
                        },
                        {
                            title: 'ROLES_&_PERMISSIONS',
                            id: 'roles-permissions',
                            routerLink: '/roles',
                            show:
                                this.featureService.checkAccess(
                                    'tc-roles-and-permissions'
                                ) &&
                                this.permissionsService.isListGranted(
                                    PermissionResourcesEnum.RolesPermissions
                                ),
                        },
                    ],
                },
                {
                    title: 'BACKGROUND_SCREENING',
                    id: 'background-screening',
                    openSubMenu: this.setSidebarActive(
                        'background-screening-orders',
                    ),
                    routerLink: '/background-screening',
                    icon: 'background-screening.svg',
                    show: (this.permissionsService.isViewGranted(PermissionResourcesEnum.BackgroundScreeningOrders)
                        || this.permissionsService.isListGranted(PermissionResourcesEnum.BackgroundScreeningOrders)
                    ),
                    subMenu: [
                        {
                            title: 'BACKGROUND_SCREENING_ORDERS_NAVBAR',
                            id: 'background-screening-orders',
                            routerLink: 'background-screening-orders',
                            show:
                                this.permissionsService.isViewGranted(PermissionResourcesEnum.BackgroundScreeningOrders),
                        }
                    ],
                },
            ];
        }
        this.showSettings =
            this.permissionsService.isViewGranted(
                PermissionResourcesEnum.GeneralSettings
            ) ||
            this.permissionsService.isEditGranted(
                PermissionResourcesEnum.Integrations
            ) ||
            this.permissionsService.isEditGranted(
                PermissionResourcesEnum.Notifications
            ) ||
            this.permissionsService.isEditGranted(
                PermissionResourcesEnum.OutgoingEmail
            ) ||
            this.permissionsService.isEditGranted(
                PermissionResourcesEnum.DrugandAlcoholTestSettings
            ) || this.permissionsService.isViewGranted(
                PermissionResourcesEnum.TruckBinders
            );
    }

    ngOnDestroy(): void {
        if (this.userIdSub) this.userIdSub.unsubscribe();
        if (this.event$) this.event$.unsubscribe();
        if (this.screenWidthSub) this.screenWidthSub.unsubscribe();
        if (this.permissionsSub) this.permissionsSub.unsubscribe();
        if (this.companyNameSub) this.companyNameSub.unsubscribe();
    }
}
