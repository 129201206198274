<div class="discard-container">
    <img src="/assets/images/warning-circles.svg">
    <h6 class="heading-6 title">
        {{"UNSAVED_CHANGES" |translate}}
    </h6>
    <p class="body-short-1">
        {{"DO_YOU_WANT_TO_SAVE_OR_DISCARD_CHANGES" |translate}} 
    </p>
    <div class="action-buttons">
        <button class="secondary-button-large" (click)="discard()">
            {{"DISCARD" |translate}}
        </button>
        <button class="primary-button-large" (click)="save()">
            {{"SAVE_CHANGES" |translate}}
        </button>
    </div>
</div>