import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { ContextService } from 'src/app/shared/services/context.service';

@Component({
    selector: 'app-welcome-page',
    standalone: true,
    imports: [
        TranslateModule
    ],
    templateUrl: './welcome-page.component.html',
    styleUrl: './welcome-page.component.scss'
})
export class WelcomePageComponent implements OnInit {

    constructor(
        public contextService: ContextService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    year: number = new Date().getFullYear();
    companyId: number | null = null;

    async ngOnInit() {
        this.companyId = Number(this.route.snapshot.queryParams['companyId']);
        await this.contextService.rereadAllCompanies();
        let selectedCompany;
        if(this.companyId != 0){
            selectedCompany = this.contextService.employeeCompanies$
            .getValue()
            .find((item) => item.id == this.companyId) ?? null;
            if (selectedCompany) {
                this.contextService.companyId$.next(selectedCompany?.id ?? 0);
                this.contextService.selectCompany(selectedCompany?.id ?? 0);
                this.contextService.companyName$.next(selectedCompany?.name ?? "Select Company");
                this.contextService.companyLogoId$.next(selectedCompany?.logoDocumentId ?? 0);
            }
        }
        
    }

    proceed() {
        const PERMISSIONS_KEY = 'user-permissions';
        const permissionList = localStorage.getItem(PERMISSIONS_KEY)
            ? JSON.parse(localStorage.getItem(PERMISSIONS_KEY) ?? '')
            : null;
        if (permissionList && permissionList.userResourcesPermissions.length > 1) {
            this.router.navigate(['/home'])
        } else {
            this.router.navigate(['/no-permissions'])
        }
    }

}
