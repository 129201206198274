import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedService } from '../../shared/services/shared.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { ContextService } from 'src/app/shared/services/context.service';
import { ApiResponse } from 'src/app/core/models/api-response.model';
import { tcRequireToken } from 'src/app/core/interceptors/auth.interceptor';
import { environment } from 'src/environments/environment';
import { FormService } from 'src/app/shared/services/form.service';

interface Errors {
    missingUppercase?: boolean;
    missingLowercase?: boolean;
    missingSymbol?: boolean;
    missingNumber?: boolean;
}

enum ValidateTokenErrors {
    EmailAlreadyVerified = 'EmailAlreadyVerified',
    EmailNotExist = 'EmailNotExist',
    InvalidToken = 'InvalidToken',
    EmptyToken = 'EmptyToken',
    Valid = 'Valid'
}

interface ValidateActivateAccountToken {
    emailToken: string;
    email: string;
    userId: number;
    verificationResult: ValidateTokenErrors
    isPasswordSet: boolean
}

const APIURL = `${environment.apiUrl}/cmp/EmployeeSetup/`;

@Component({
    selector: 'app-driver-app-invitation',
    templateUrl: './driver-app-invitation.component.html',
    styleUrls: ['./driver-app-invitation.component.scss']
})
export class DriverAppInvitationComponent {

    constructor(
        private route: ActivatedRoute,
        private sharedService: SharedService,
        private router: Router,
        private contextService: ContextService,
        private http: HttpClient,
        private formService: FormService
    ) { }

    requestOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }),
        responseType: 'json' as 'json',
    };
    isBusy: number = 0;
    token: string = '';
    submitted: boolean = false;
    toggleNewPasswordInputType: boolean = false;
    toggleconfirmNewPasswordInputType: boolean = false;
    errorMessages: Set<string> = new Set();
    accountActivate: boolean = false;

    form = new FormGroup({
        newPassword: new FormControl('', [
            Validators.required,
            Validators.minLength(6),
            this.formService.passwordValidator,
        ]),
        confirmNewPassword: new FormControl('', [Validators.required]),
    });

    get newPassword() {
        return this.form.get('newPassword')!;
    }
    get confirmNewPassword() {
        return this.form.get('confirmNewPassword')!;
    }

    async ngOnInit() {
        this.token = this.route.snapshot.queryParams['t'];
        await this.ValidateActivateAccountToken();

    }

    async ValidateActivateAccountToken() {
        try {
            this.isBusy++;
            let response = await this.activateAccount(this.token);
            if (response.success && response.data) {
                switch (response.data.verificationResult) {
                    case ValidateTokenErrors.Valid:
                        if (this.contextService.getUserId() > 0 && response.data.userId != this.contextService.getUserId())
                            this.router.navigate([`/token-validations/wrongAccount/${response.data.email}`]);
                        else if (response.data.isPasswordSet) {
                            this.router.navigate(['/driver-app']);
                        }
                        break;
                    case ValidateTokenErrors.EmailAlreadyVerified:
                        if (this.contextService.getUserId() == 0)
                            this.router.navigate(['/driver-app']);
                        else if (this.contextService.getUserId() > 0 && response.data.userId == this.contextService.getUserId())
                            this.router.navigate(['/driver-app']);
                        else if (this.contextService.getUserId() > 0 && response.data.userId != this.contextService.getUserId())
                            this.router.navigate([`/token-validations/wrongAccount/${response.data.email}`]);
                        break;
                    case ValidateTokenErrors.EmailNotExist:
                    case ValidateTokenErrors.InvalidToken:
                    case ValidateTokenErrors.EmptyToken:
                        this.router.navigate(['/token-validations/accountActivationLinkNoLongerValid']);
                        break;
                };
            } else {
                this.router.navigate(['/token-validations/accountActivationLinkNoLongerValid']);
            }
        } catch (error: any) {
            this.router.navigate(['/token-validations/accountActivationLinkNoLongerValid']);
            this.sharedService.alertDangerMessage();
        } finally {
            this.isBusy--;
        }
    }

    async submit() {
        this.submitted = true;
        if (this.form.invalid) {
            return;
        }
        if (this.form.value.newPassword != this.form.value.confirmNewPassword) {
            this.errorMessages.add('Passwords don’t match');
            return;
        } else {
            this.errorMessages.delete('Passwords don’t match');
        }
        try {
            this.isBusy++;
            let response = await this.setPasswordApi(
                this.token,
                this.form.value.newPassword!
            );
            if (response.success) {
                this.sharedService.alertSuccessMessage();
                this.router.navigate([`/driver-app`]);
            }
        } catch (error: any) {
            this.sharedService.alertDangerMessage();
            this.errorMessages.add(
                error?.error?.error?.isUserError
                    ? error?.error?.error?.message
                    : 'SOMETHING_WENT_WRONG_TRY_LATER'
            );
        } finally {
            this.isBusy--;
        }
    }

    async activateAccount(token: string) {
        return await lastValueFrom(this.http
            .post<ApiResponse<ValidateActivateAccountToken>>(
                APIURL + 'ActivateAccount',
                { token },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
    }

    async setPasswordApi(token: string, password: string) {
        return await lastValueFrom(this.http
            .post<ApiResponse<ValidateActivateAccountToken>>(
                APIURL + 'SetPassword',
                { token, password },
                { ...this.requestOptions, context: tcRequireToken(false) }
            ));
    }

}
