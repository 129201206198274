<div class="dialog-header">
    <div class="row">
        <h5 class="dialog-title">
            {{ "SIGNATURE_DETAILS" | translate }}
        </h5>
    </div>
    <div class="close-icon" (click)="cancel()">
        <img (click)="cancel()" src="/assets/images/x-close-black.svg" />
    </div>
</div>

<div class="dialog-body" [class.wrapper-disabled]="isBusy">
    <!-- <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label for="signed-on">{{ "SIGNED_ON" | translate }}</label>
                <div class="body-short-1">
                    <a role="button">document.name</a>
                </div>
            </div>
        </div>
    </div> -->
    <form [formGroup]="form">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="full-name">{{ "FULL_NAME" | translate }}</label>
                    <input type="text" class="form-control" formControlName="fullName" readonly>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="email-address">
                        {{ "EMAIL_ADDRESS" | translate }}
                    </label>
                    <input type="text" class="form-control" formControlName="email" readonly>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="dated"> {{ "DATED" | translate }} </label>
                    <input type="text" class="form-control" formControlName="dated" readonly>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="timestamp"> {{ "TIME_STAMP" | translate }} </label>
                    <input type="text" class="form-control" formControlName="timeStamp" readonly>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="ip-address"> {{ "IP_ADDRESS" | translate }} </label>
                    <input type="text" class="form-control" formControlName="ipAddress" readonly>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="device-used">
                        {{ "DEVICE_USED" | translate }}
                    </label>
                    <input type="text" class="form-control" formControlName="deviceUsed" readonly>
                </div>
            </div>
        </div>
        <ng-container *ngIf="signatureImgLink">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="signature">
                            {{ "SIGNATURE" | translate }}
                        </label>
                        <img [src]="signatureImgLink  | safe" alt="" />
                    </div>
                </div>
            </div>
        </ng-container>
    </form>
</div>
<div class="dialog-footer">
    <div class="app-actions-button">
        <button class="app-close-modal-button" (click)="cancel()">
            {{ "CLOSE" | translate }}
        </button>
    </div>
</div>
