import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { InputUnitTypeEnum } from '../../model/shared.model';

@Component({
    selector: 'app-input-unit',
    templateUrl: './input-unit.component.html',
    styleUrls: ['./input-unit.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => InputUnitComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => InputUnitComponent),
        },
    ],
})
export class InputUnitComponent implements OnInit, ControlValueAccessor, Validator {

    constructor() { }

    @Input() viewMode: boolean = false;
    @Input() id: string = '';
    @Input() inputUnitType: InputUnitTypeEnum | null = null;
    @Input() placeholder: string = "";
    @Input() readonly: boolean = false;
    @Input() maxlength: number = 15;
    @Input() isInitValueNull: boolean = false;
    @Input() decimalPlaces: number = 0;
    @Input() isAllowDecimal: boolean = false;
    

    inputUnitValue: number | string | null = this.isInitValueNull ? null : 0;

    public get InputUnitTypeEnum(): typeof InputUnitTypeEnum {
        return InputUnitTypeEnum;
    }

    ngOnInit(): void {

    }

    ///////////////
    touched = false;
    disabled = false;
    onChange = (val: number |string| null) => {
        this.writeValue(val);
    };
    onTouched = () => { };

    validate(c: AbstractControl): ValidationErrors | null {
        return null;
    }

    registerOnValidatorChange?(fn: () => void): void {
        // console.log('Method not implemented.');
    }

    writeValue(value: number |string| null): void {
        if (value) {
            this.inputUnitValue = this.formatValue(value);
        }
    }
    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
    //////////////

    inputChange(value: number | string) {
        this.markAsTouched();
        // const regex = new RegExp(`^-?\\d+(\\.\\d{0,${this.decimalPlaces}})?$`);
        // if(value){
        //     if (!regex.test(value.toString())) {
        //         value =value.toString().substring(0, value.toString().length - 1); // Remove the last character if invalid
        //       }    
        // }
        
        const sanitizedValue = value.toString().replace(/,/g, '');
        let valueNew: number | null = Number(sanitizedValue);
    
        if (this.isInitValueNull && valueNew === 0) {
            valueNew = null;
        }
    
        if (valueNew !== null) {
            this.onChange(valueNew);
        } else {
            this.inputUnitValue = sanitizedValue;
            this.onChange(valueNew);
        }
    }

    checkLength2(e: any, input: any) {
        let functionalKeys;
        if(this.isAllowDecimal) {
            functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft', 'Tab' , '.'];
        } else {
            functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft', 'Tab'];
        }
        const ctrlKeys = ['a', 'c', 'v', 'x', 'z']; // add more keys as needed

        // Check if Ctrl or Cmd key is pressed
        if (e.ctrlKey || e.metaKey) {
            if (ctrlKeys.includes(e.key.toLowerCase())) {
                return;
            }
        }

        if (functionalKeys.indexOf(e.key) !== -1) {
            return;
        }

        const keyValue = +e.key;
        if (isNaN(keyValue)) {
            e.preventDefault();
            return;
        }

        const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
        let newValue;
        if (hasSelection) {
            newValue = this.replaceSelection(input, e.key)
        } else {
            newValue = input.value + keyValue.toString();
        }

        if (newValue.length > this.maxlength) {
            e.preventDefault();
        }
    }

    private replaceSelection(input: any, key: any) {
        const inputValue = input.value;
        const start = input.selectionStart;
        const end = input.selectionEnd || input.selectionStart;
        return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
    }

    formatValue(value: string | number): string {
        const numberValue = Number(value);
        const roundedValue = Math.round(numberValue); // Round the number

        // Check if decimal places is greater than zero
        if (this.decimalPlaces > 0) {
            // Check if there’s a non-zero decimal part
            const decimalPart = (numberValue % 1);
            if (decimalPart === 0) {
                return roundedValue.toString(); // Return integer as string
            }
            
            // Format the number according to decimal places
            const parts = roundedValue.toString().split('.');
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            
            // Handle the decimal part
            parts.push((decimalPart.toFixed(this.decimalPlaces).substring(2))); // Add decimal part (the substring() removes the "0.")
            return parts.join('.');
        } else {
            // No decimal places, return rounded number formatted
            return roundedValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
    }

    onFocus() {
        if (typeof this.inputUnitValue === 'string') {
            this.inputUnitValue = this.inputUnitValue.replace(/,/g, '');
        }
    }
    
    onBlur() {
        if (this.inputUnitValue !== null) {
            this.inputUnitValue = this.formatValue(this.inputUnitValue);
        }
    }

}