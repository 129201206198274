import { ThrottleCallService } from './../../services/throttle-call.service';
import { startWith, map } from 'rxjs/operators';
import {
    ControlValueAccessor,
    Validator,
    NG_VALUE_ACCESSOR,
    NG_VALIDATORS,
    AbstractControl,
    ValidationErrors,
    UntypedFormControl,
} from '@angular/forms';
import { Observable, lastValueFrom } from 'rxjs';
import { ActionResponse, DropdownList, GetDataOptions } from './../../model/shared.model';
import {
    Component,
    Input,
    OnInit,
    forwardRef,
    Inject,
    HostListener,
    ElementRef,
    ViewChild,
    AfterViewInit,
    OnDestroy,
    Output,
    EventEmitter,
    SimpleChanges,
    OnChanges,
    ChangeDetectorRef,
} from '@angular/core';
import { AutocompleteDropdown } from '../../model/shared.model';
import { DOCUMENT } from '@angular/common';
import { PermissionsService } from '../../services/permissions.service';
import { TranslateService } from '@ngx-translate/core';
import { PagingData } from 'src/app/core/models/api-response.model';

@Component({
    selector: 'app-autocomplete-dropdown',
    templateUrl: './autocomplete-dropdown.component.html',
    styleUrls: ['./autocomplete-dropdown.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: forwardRef(() => AutocompleteDropdownComponent),
        },
        {
            provide: NG_VALIDATORS,
            multi: true,
            useExisting: forwardRef(() => AutocompleteDropdownComponent),
        },
    ],
})
export class AutocompleteDropdownComponent<T>
    implements
    OnInit,
    ControlValueAccessor,
    Validator,
    AfterViewInit,
    OnChanges,
    OnDestroy {
    @HostListener('window:scroll', []) onWindowScroll() {
        this.showDropdownMenu = false;
        this.relocateList();
    }

    @HostListener('window:resize', []) onWindowResize() {
        this.showDropdownMenu = false;
        this.relocateList();
    }

    @ViewChild('dropdownContainer') dropdownContainer: ElementRef | null = null;
    @ViewChild('list') list: ElementRef | null = null;
    isBusy: number = 0;
    containerTopInit: number | null = null;
    autocompleteDropdownConfig: AutocompleteDropdown<T> | null = null;
    @Input() set autoCompleteOptions(data: AutocompleteDropdown<T> | null) {
        this.autocompleteDropdownConfig = data;
        if (this.autocompleteDropdownConfig?.getAllData)
            this.optionsApi.pageSize = 222222224;
        this.optionsList = [];
        this.getList();
    }
    @Input() strictValue: boolean = false;
    @Input() optionsList: DropdownList[] = [];
    @Input() readonly: boolean = false;
    @Input() placeholder: string = '';
    @Input() customValueLabel: any | null = null;
    @Input() tooltipTemplate: any | null = null;
    @Input() tooltipWidth: string | null = null;
    @Input() showDropdownMenu: boolean = false;
    @Input() id: string = '';
    @Input() height: string = '17rem';
    @Input() viewMode: boolean = false;
    @Input() filedError: boolean = false;
    @Input() isTableEditable: boolean = false;
    @Input() openlistInTop: boolean = false;
    @Input() maxlength: number = 50;
    @Output() dataObject = new EventEmitter<T>();
    @Output() blurInput = new EventEmitter<T>();
    notSelectedFromAvailable: boolean = false;
    originalValue: string = '';
    positionInterval: any;
    optionsApi: GetDataOptions = {
        sort: '',
        pageSize: 25,
        pageIndex: 0,
        searchString: '',
    };
    label: string = '';
    filteredOptions: Observable<DropdownList[]> | null = null;
    myControl = new UntypedFormControl('');
    dropdownSelectedObj: DropdownList | null = null;
    isLoading: boolean = false;
    private toggleDropdown: boolean = true;
    arrowClicked: boolean = false;

    constructor(
        @Inject(DOCUMENT) private document: any,
        private permissionsService: PermissionsService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef
    ) {
        parent;
    }

    @HostListener('document:click', ['$event']) onDocClick(event: any) {
        const outsideClick =
            typeof event.composedPath === 'function' &&
            !event
                .composedPath()
                .includes(this.dropdownContainer?.nativeElement);

        if (outsideClick) {
            this.showDropdownMenu = false;
        }
    }

    ngOnInit(): void {
        if (this.autocompleteDropdownConfig?.allowAddNew) {
            if (this.autocompleteDropdownConfig.permissionResource)
                this.autocompleteDropdownConfig.allowAddNew =
                    this.permissionsService.isAddGranted(
                        this.autocompleteDropdownConfig.permissionResource
                    );
            else this.autocompleteDropdownConfig.allowAddNew = false;
        }
        // document.getElementById("dropdownMenuLink")?.click();
        if (this.autocompleteDropdownConfig?.searchFromApi) {
            this.filteredOptions = this.myControl.valueChanges.pipe(
                startWith(''),
                map((value) => this._filter(''))
            );
            // this.myControl.valueChanges.subscribe(value => {
            //     this.optionsApi.searchString = value;
            //     this.tc.call(() => {
            //         this.optionsList = [];
            //         return this.getList();
            //     }, 500);

            // })
        } else {
            this.filteredOptions = this.myControl.valueChanges.pipe(
                startWith(''),
                map((value) => this._filter(value || ''))
            );
        }

        this.positionInterval = setInterval(() => {
            this.relocateList();
        }, 1);
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

    ngAfterViewInit() {
        this.relocateList();
    }

    // openDropDownMenu() {
    //     // let el = document.getElementById("dropdownMenuLink");
    //     if (this.toggleDropdown) {
    //         this.document.getElementById('DropDownMenu').classList.add('show');
    //         this.document.getElementById('DropDownMenu').classList.add('static-dropdown');
    //         document.getElementById('autocomplete-dropdown-input')?.classList.add('show');
    //         document.getElementById('autocomplete-dropdown-input')?.setAttribute('aria-expanded', 'false')
    //         document.getElementById('DropDownMenu')?.setAttribute('data-popper-placement', 'bottom')

    //     } else {
    //         this.document.getElementById('DropDownMenu').classList.remove('show');
    //         this.document.getElementById('DropDownMenu').classList.remove('static-dropdown');
    //         document.getElementById('autocomplete-dropdown-input')?.classList.remove('show');
    //         document.getElementById('autocomplete-dropdown-input')?.setAttribute('aria-expanded', 'true')
    //     }
    //     this.toggleDropdown = !this.toggleDropdown;
    // }

    firstCall: boolean = true;
    scrollTo: number = 0;
    hasNextPage: boolean = true;
    formSearch: boolean = false;
    async onScroll(event: any) {
        if (this.autocompleteDropdownConfig?.getAllData == false)
            if (
                event.target.offsetHeight + event.target.scrollTop >=
                event.target.scrollHeight - 800 &&
                this.firstCall && !this.formSearch
            ) {
                this.firstCall = false;
                this.optionsApi.pageIndex = this.optionsApi.pageIndex + 1;
                if (this.hasNextPage) {
                    await this.getList();
                    this.firstCall = true;
                } else {
                    this.firstCall = true;
                }
            }
    }

    private isPaginated = (object: PagingData<T> | T[]): object is PagingData<T> => {
        return 'pageData' in object;
    };

    private tc = new ThrottleCallService<ActionResponse<T>>();
    async getList(delay = 0) {
        try {
            this.isLoading = true;
            if (delay == 0) this.isBusy++;
            if (this.autocompleteDropdownConfig) {
                if (this.autocompleteDropdownConfig.resetList) {
                    this.optionsList = [];
                }
                //console.log('fetch from database')
                let response = await this.tc.call(
                    () => this.autocompleteDropdownConfig!.getData(this.optionsApi),
                    delay
                );
                //console.log('completed fetch from database')
                if (response) {
                    if (response.data && this.isPaginated(response.data)) {
                        this.hasNextPage = response.data.hasNextPage;
                    }
                    if (response.autoCompList) {
                        this.optionsList.push(...response.autoCompList);
                    }
                    if (this.autocompleteDropdownConfig?.itemsToExclude) {
                        this.autocompleteDropdownConfig.itemsToExclude.forEach(
                            (id) => {
                                this.optionsList = this.optionsList.filter(
                                    (op) => op.value != id
                                );
                            }
                        );
                    }
                }
                if (this.autocompleteDropdownConfig.searchFromApi) {
                    this.filteredOptions = this.myControl.valueChanges.pipe(
                        startWith(''),
                        map((value) => this._filter(''))
                    );
                    // this.onChange(this.myControl.value);
                } else {
                    this.filteredOptions = this.myControl.valueChanges.pipe(
                        startWith(''),
                        map((value) => this._filter(value || ''))
                    );
                }
            }
        }
        catch (error: any) {

        } finally {
            this.isLoading = false;
        }
    }

    private _filter(value: any): DropdownList[] {
        if (value == undefined) value = '';
        const filterValue = value.toLowerCase();
        let data = this.optionsList.filter((option: DropdownList) =>
            option?.text?.toLowerCase().includes(filterValue)
        );
        return data;
    }

    changeInput(event: any) {
        this.itemIndex = 0;
        let value = this.myControl.value;
        let obj = this.optionsList.find((e) => e.value == value);
        if (obj) {
            this.showDropdownMenu = true;
            // this.setControlValue(obj.text, obj);
            this.dataObject.emit(obj?.data);
            this.originalValue = obj.text;
            if (!this.strictValue)
                this.dropdownSelectedObj = obj?.data;
        } else {
            this.dataObject.emit(undefined);
            if (!this.strictValue)
                this.dropdownSelectedObj = null;
        }
        this.markAsTouched();
        this.onChange(value);
        if (this.autocompleteDropdownConfig?.searchFromApi) {
            this.optionsApi.searchString = value;
            this.optionsList = [];
            this.optionsApi.searchById = 0;
            this.optionsApi.pageIndex = 0;
            this.getList(500);
        }
        this.relocateList();
    }

    changeValue(item: any) {
        this.itemIndex = 0;
        let obj = this.optionsList.find((e) => e.value == item);
        if (obj) {
            this.setControlValue(obj.text, obj);
            this.originalValue = obj.text;
            this.filteredOptions = this.myControl.valueChanges.pipe(
                startWith(''),
                map((value) => this._filter(value || ''))
            );
            this.showDropdownMenu = false;
        }
        this.dataObject.emit(obj?.data);
        this.markAsTouched();
        this.onChange(item);
    }

    async setControlValue(value: any, obj?: DropdownList) {
        if (obj != null) {
            this.dropdownSelectedObj = obj.data;
        }
        if (this.customValueLabel != null && obj) {
            this.myControl.setValue(this.customValueLabel(obj.data));
            // this.customLabel = this.customValueLabel(obj.data);
            // this.myControl.setValue(value);
        } else {
            this.myControl.setValue(
                await lastValueFrom(this.translate.get(value)));
        }
        this.originalValue = value;
    }

    async deleteSearch() {
        this.myControl.setValue(null);
        this.dataObject.emit(undefined);
        this.showDropdownMenu = true;
        let el = document.getElementById(this.id);
        if (el) {
            el.focus();
        }
        if (this.autocompleteDropdownConfig) {
            this.optionsApi.searchString = '';
            this.optionsList = [];
            this.optionsApi.searchById = 0;
            this.optionsApi.pageIndex = 0;
            this.optionsApi.pageSize = 25;
            this.firstCall = true;
            this.formSearch = true;
            await this.getList(500);
            this.showDropdownMenu = true;
            setTimeout(() => {
                this.formSearch = false;
            }, 2000);
        }
        this.markAsTouched();
        this.onChange(null);
    }

    onBlurInput() {
        setTimeout(() => {
            if (!this.arrowClicked) {
                this.showDropdownMenu = false;
            }
            this.arrowClicked = false;
        }, 250);
        this.blurInput.emit();
    }

    async inputClick() {
        // this.itemIndex = 0;
        if (this.optionsList.length == 0) {
            this.optionsApi.searchById = null;
            this.optionsApi.pageIndex = 0;
            this.optionsApi.pageSize = 25;
            await this.getList();
        }
        this.showDropdownMenu = true;
        this.relocateList();
    }

    arrowClick() {
        this.arrowClicked = true;
        this.showDropdownMenu = !this.showDropdownMenu;
        this.relocateList();
    }

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        switch (event.key) {
            case 'ArrowDown':
                this.removeActiva();
                this.arrowDown();
                break;
            case 'ArrowUp':
                this.removeActiva();
                this.arrowUp();
                break;
            case 'Enter':
                this.enter();
        }
    }

    itemIndex: number = 0;

    enter() {
        let el = document.getElementById(`menu-item-${this.itemIndex}`);
        if (el) {
            this.changeValue(el.getAttribute('value'));
            setTimeout(() => {
                this.showDropdownMenu = false;
            }, 250);
        }
    }
    removeActiva() {
        let el = document.getElementById(`menu-item-${this.itemIndex}`);
        if (el) {
            el.classList.remove('item-hover');
        }
    }
    arrowDown() {
        if (this.itemIndex > this.optionsList.length - 1) {
            document
                .getElementById(`menu-item-${this.optionsList.length}`)
                ?.classList.add('item-hover');
            return;
        }
        this.itemIndex++;
        let el = document.getElementById(`menu-item-${this.itemIndex}`);
        if (el) {
            el.classList.add('item-hover');
        }
        this.scrollToElementById(`menu-item-${this.itemIndex}`);
    }

    arrowUp() {
        if (this.itemIndex == 1) {
            document.getElementById(`menu-item-1`)?.classList.add('item-hover');
            return;
        }
        this.itemIndex--;
        let el = document.getElementById(`menu-item-${this.itemIndex}`);
        if (el) {
            el.classList.add('item-hover');
        }
        this.scrollToElementById(`menu-item-${this.itemIndex}`);
    }

    scrollToElementById(id: string) {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start',
            });
        }
    }

    //////////////////////////////////

    onChange = (value: any) => { };
    onTouched = () => { };
    touched = false;
    disabled = false;

    validate(c: AbstractControl): ValidationErrors {
        if (this.strictValue && this.myControl.value) {
            let obj = this.optionsList.find(
                (e) =>
                    e.text == this.originalValue ||
                    e.value == this.originalValue
            );
            if (obj == undefined) {
                this.notSelectedFromAvailable = true;
                return {
                    notSelectedFromAvailable: {
                        notAvailable: true,
                    },
                };
            } else {
                this.notSelectedFromAvailable = false;
                return {};
            }
        } else {
            return {};
        }
    }

    registerOnValidatorChange?(fn: () => void): void {
        // console.log('Method not implemented.');
    }

    async writeValue(value: any) {
        if (value) {
            if (this.strictValue) {
                let obj = this.optionsList.find(
                    (e) => e.text == value || e.value == value
                );
                if (obj == undefined) {
                    if (this.autocompleteDropdownConfig) {
                        if (this.autocompleteDropdownConfig.searchUseString) {
                            this.optionsApi.searchString = String(value);
                        } else {
                            this.optionsApi.searchById = String(value);
                        }
                        this.optionsApi.pageIndex = 0;
                        if (!this.autocompleteDropdownConfig.searchFromApi) {
                            this.optionsApi.pageSize = 222222224;
                        } else {
                            this.optionsApi.pageSize = 25;
                        }
                        await this.getList();
                        let objSearch = this.optionsList.find(
                            (e) => e.text == value || e.value == value
                        );
                        if (objSearch) {
                            this.dataObject.emit(objSearch.data);
                            this.setControlValue(objSearch.text, objSearch);
                            this.onChange(objSearch.value);
                        }
                    }
                } else {
                    this.setControlValue(obj.text, obj);
                }
            } else {
                if (this.autocompleteDropdownConfig && this.tooltipTemplate) {
                    if (this.autocompleteDropdownConfig.searchUseString) {
                        this.optionsApi.searchString = String(value);
                    } else {
                        this.optionsApi.searchById = String(value);
                    }
                    this.optionsApi.pageIndex = 0;
                    if (!this.autocompleteDropdownConfig.searchFromApi) {
                        this.optionsApi.pageSize = 222222224;
                    } else {
                        this.optionsApi.pageSize = 25;
                    }
                    await this.getList();
                    let objSearch = this.optionsList.find(
                        (e) => e.text == value || e.value == value
                    );
                    if (objSearch) {
                        this.dataObject.emit(objSearch.data);
                        this.setControlValue(objSearch.text, objSearch);
                        this.onChange(objSearch.value);
                    } else {
                        this.setControlValue(value);
                    }
                }
                this.setControlValue(value);
            }
        }
    }
    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }

    markAsTouched() {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }

    private relocateList() {
        let containerWidth =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().width;
        let containerLeft =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().left;

        let containerTop =
            this.dropdownContainer?.nativeElement.getBoundingClientRect().top;

        let listWidth = this.list?.nativeElement.getBoundingClientRect().width;
        let listLeft = this.list?.nativeElement.getBoundingClientRect().left;
        let listTop = this.list?.nativeElement.getBoundingClientRect().top;
        let listHeghit = this.list?.nativeElement.getBoundingClientRect().height;
        // console.log(listHeghit)
        if (this.list) {
            this.list.nativeElement.style.width! = containerWidth + 'px';
            this.list.nativeElement.style.left! = containerLeft + 'px';
            if (this.openlistInTop) {
                this.list.nativeElement.style.top! = containerTop - listHeghit - 3 + 'px';
            } else {
                this.list.nativeElement.style.top! = containerTop + 32 + 'px';
            }
            // if (this.notSelectedFromAvailable) {
            //     this.showDropdownMenu = false;
            // } else this.showDropdownMenu = !this.showDropdownMenu ;
            if (this.containerTopInit) {
                if (
                    this.containerTopInit != containerTop &&
                    this.showDropdownMenu
                ) {
                    this.showDropdownMenu = false;
                }
            } else {
                this.containerTopInit = containerTop;
            }
            this.containerTopInit = containerTop;
        }
        if (this.optionsList.length > 0) {
            this.isLoading = false;
        }
    }

    async addNew() {
        if (
            this.autocompleteDropdownConfig &&
            this.autocompleteDropdownConfig.addNewFunc
        )
            await this.autocompleteDropdownConfig.addNewFunc();
    }

    ngOnDestroy(): void {
        clearInterval(this.positionInterval);
    }
}
